import gravatarUrl from 'gravatar-url';
import { StringifyOptions } from 'query-string';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { AuthProvider, GetManyResult, UserIdentity, fetchUtils } from 'react-admin';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import { independentSystemWidgetIds } from '../../screens/dashboard/constants';
import { capitalizeFirstLetter } from '../../utility/functions';
import {
  AnalysisRequest,
  AnalysisResponse,
  AvailabilityResponse,
  BinaryUploadParams,
  BloxCustomDataProviderType,
  ChatQueryParams,
  ChatQueryResult,
  ChatRequest,
  DefaultSiteResult,
  DefaultSavedSearchResult,
  FullErrorResponse,
  GetAudienceByIdResult,
  GetDashboardByIdParams,
  GetEditorialConfigResult,
  GetKeywordListResult,
  GetSectionListResult,
  GetServiceTypesParams,
  GetServiceTypesResult,
  GetServicesParams,
  GetServicesResult,
  GetSitesParams,
  GetSitesResult,
  GetUserAccountByIdResult,
  GetUserAccountSearchResult,
  GetUserGroupsByIdParams,
  GetUserGroupsByIdResult,
  GetUserSubscriptionsByIdParams,
  GetUserSubscriptionsByIdResult,
  GetWorkflowByIdResult,
  GetWorkflowsResult,
  KeywordListItem,
  KeywordListSearchParams,
  KeywordSuggestion,
  OmnibarGPTQueryParams,
  OmnibarGPTQueryResult,
  SectionListItem,
  SectionListSearchParams,
  SectionTreeResult,
  UserAccountGroups,
  ServiceItem,
  ServiceTypeItem,
  SessionConfigResult,
  SiteItem,
  TGetDashboardByIdResponse,
  TGetWidgetByIdResponse,
  GetAssetPreviewResult,
  UpdateUserGroupsByIdResult,
  UserAccountSearchParams,
  UserAccountSummary,
  UserWallet,
  TDashboardWidgetDataResults,
} from '../../utility/types/dataProvider';
import { useCosmosAuthEnv } from './CosmosAuthEnvContext';
import { useCosmos } from './CosmosContext';

// --- Helper Functions ---

/** Process related content/children */
function relatedContent(params: any) {
  if (!params.content) {
    return params;
  }
  const htmlString = params.content;
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string to create an HTMLElement
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.documentElement;
  const bloxInlineElements = element.querySelectorAll('blox-inline');
  const extractedData = [];
  for (const bloxInlineElement of bloxInlineElements) {
    if (bloxInlineElement.getAttribute('placeholder') === 'true') {
      continue; // Skip processing if placeholder is true
    }

    extractedData.push({
      uuid: bloxInlineElement.getAttribute('uuid'),
      app: bloxInlineElement.getAttribute('app'),
      type: 'child',
    });
  }

  const children = params.children
    ? [...params.children, ...extractedData]
    : extractedData;

  const transformedData = {
    ...params,
    children: children,
  };

  return transformedData;
}

/** Handle TOC */
function stripTOC(params: any) {
  if (!params.content) {
    return params;
  }
  const htmlString = params.content;
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string to create an HTMLElement
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Get the root element of the document
  const elements = doc.documentElement;

  // Get all elements in the DOM
  const allElements = elements.querySelectorAll('*');

  // Iterate through each element
  allElements.forEach(function (element) {
    // Remove ID attribute
    element.removeAttribute('id');
    // Remove data-toc-id attribute
    element.removeAttribute('data-toc-id');
    // Remove data-toc-uuid attribute
    element.removeAttribute('data-toc-uuid');
  });

  // Serialize the modified document back to HTML string
  const modifiedHtmlString = elements.outerHTML;

  // Return the modified content
  return {
    ...params,
    content: modifiedHtmlString,
  };
}

/** Determine the items unique id for React-Admin's key prop */
function determineUniqueId(item: any, resource: string) {
  switch (resource) {
    case 'editorial': {
      return item?.uuid;
    }
    case 'user': {
      // React-Admin Unique Key workaround - Concatenate subscription ID if exists.
      // /user/account/search could return multiple users with same UUID, but different subscription IDs rows
      // when payload has subscription == true in query, or view_by subscription, so concat if exists to get unique.
      const uniqueId = `${item?.uuid}${item?.subscription?.id || ''}`;
      return uniqueId;
    }
    case 'user/activities': {
      return item?.id; // This endpoint uses id as unique key.
    }
    case 'savedsearch': {
      return item?.id; // This endpoint uses id as unique key.
    }
    default: {
      console.warn('[determineUniqueId] - defaulted!');
      return item?.uuid || item?.id || undefined;
    }
  }
}

const createErrorResponse = (json: any): FullErrorResponse => ({
  status: json?.status || 'Unspecified',
  body: json || 'Unspecified',
  name: json?.name || 'Unspecified',
  message: json?.message || 'Unspecified',
  ...json, // Spread any additional info in the json response.
});

/** Assert the API responded with successful fetch status
 * @param {any} json - The JSON response from the API
 * @param {number} status - The HTTP status code
 * @param {string} [body] - The response body as a string
 * @param {Headers} [headers] - The response headers
 * @throws {FullErrorResponse} - Throws the full error response object
 **/
export async function assertResponseSuccess(
  json: any,
  status: number,
  body?: string,
  headers?: Headers,
) {
  // Anything in range 200-299 should be considered a success e.g. 200, 204, etc.
  if (status < 200 || status > 299) {
    throw createErrorResponse(json);
  }

  // Some endpoints return 204 code on success, with no acutal json.status
  // response so we need to check for that and return early.
  if (status === 204) {
    return;
  }

  // Otherwise check the status string on the json
  if (json?.status && json.status !== 'success') {
    throw createErrorResponse(json);
  }
}

export interface CosmosDataProviderContextType {
  dataProvider: BloxCustomDataProviderType;
  authProvider: AuthProvider;
}

export const CosmosRAProvidersContext = createContext<
  CosmosDataProviderContextType | undefined
>(undefined);

export const useCosmosRAProviders = () => {
  const context = useContext(CosmosRAProvidersContext);
  if (!context) {
    throw new Error(
      'useCosmosRAProviders must be used within a CosmosRAProvidersContext!',
    );
  }
  return context;
};

/** Builds the Auth Provider and Data Provider for React-Admin **/
export const CosmosRAProviders = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { envState } = useCosmosAuthEnv();
  const { defaultSite } = useCosmos();
  const apiUrl = `https://${envState?.API_SERVER_URL}/rest/admin`;
  const authToken = `${capitalizeFirstLetter(String(auth.user?.token_type))} ${
    auth.user?.access_token
  }`;

  const httpClient = useCallback(
    (url: string, options: fetchUtils.Options = {}) => {
      try {
        if (!authToken || !defaultSite) {
          throw new Error(
            '[dataProvider] - httpClient() is missing Auth Token and/or X-Blox-Domain. Wont execute fetch.',
          );
        }

        const headers = new Headers({
          Accept: 'application/json',
          Authorization: authToken, // "Bearer <token>"
          'X-Blox-Domain': defaultSite, // Active site/domain for the user.
          ...options.headers, // Spread include any additional headers passed to options
          'X-Request-Id': uuidv4(), // Add a unique request ID for tracking
        });

        const finalOptions: fetchUtils.Options = {
          ...options,
          headers: headers,
        };

        return fetchUtils.fetchJson(url, finalOptions);
      } catch (error) {
        console.error('[dataProvider] httpClient() error:' + error?.toString());
        throw error;
      }
    },
    [authToken, defaultSite],
  );

  const contextValue = useMemo(() => {
    const queryOptions: StringifyOptions = { arrayFormat: 'bracket' };

    // -----------------------------------------------
    // ---------------- DATA PROVIDER ----------------
    // -----------------------------------------------
    const dataProvider: BloxCustomDataProviderType = {
      getList: async (resource: string, params: any) => {
        try {
          const query = params?.query;
          const type = params?.type;
          const meta = params?.meta;
          const filter = params?.filter;
          const page = params?.pagination?.page;
          const perPage = params?.pagination?.perPage;
          const field = params?.sort?.field;
          const order = params?.sort?.order;

          let payload = {
            ...(query && { query: query }),
            ...(type && { type: type }),
            ...(page && { page: (page - 1) * perPage }),
            ...(perPage && { limit: perPage }),
            ...(order && { dir: order }),
            ...(field && { sort: field === 'id' ? 'uuid' : field }), // Use UUID for "sort by id" - note RA by default will set 'id' and 'asc' if not set.
            ...(meta && { meta: meta }),
            ...(filter && { ...filter }), // Spread filter object last into payload from RA InfListBase, etc.
          };

          let baseResource = resource;
          let subResource = ''; // resource base/sub may change in the switch.
          switch (resource) {
            case 'editorial': {
              subResource = 'asset';
              payload['preview[size1]'] = '550w'; // Add specific query parameter for editorial
              payload['preview[size2]'] = '750w';
              break; // /editorial/asset/search/
            }
            case 'user': {
              subResource = 'account';
              break; // /user/account/search/
            }
            case 'user/activities': {
              baseResource = 'user';
              subResource = 'activities';
              delete payload.sort; // https://github.com/marmelab/react-admin/issues/4906
              delete payload.dir; // RA setting sort/dir automatically. This endpoint doesn't have it.
              break; // /user/activities/search/
            }
            case 'savedsearch': {
              baseResource = 'core';
              subResource = 'savedsearch';
              delete payload.sort; // https://github.com/marmelab/react-admin/issues/4906
              delete payload.dir; // RA setting sort/dir automatically. This endpoint doesn't have it.
              break; // /core/savedsearch/search/
            }
            default: {
              console.warn('[getList] - No resourceType:', resource); // Could be an error, logging warning.
              break;
            }
          }

          const url = `${apiUrl}/${baseResource}/${subResource}/search/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;

          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const dataWithIds = json.data.items.map((item: any) => ({
            ...item,
            id: determineUniqueId(item, resource),
          }));

          const formattedResult = {
            data: dataWithIds,
            total: json.data.total,
          };

          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getList() error:' + error?.toString());
          throw error;
        }
      },

      getOne: async (resource: string, params: any) => {
        let resourceType = 'asset';
        if (resource === 'user') {
          resourceType = 'account';
        }

        if (resource === 'savedsearch') {
          resource = 'core';
          resourceType = 'savedsearch';
        }

        try {
          const query = {
            ...(resource === 'editorial' && {
              'preview[thumbnail]': '200w',
              'preview[display]': '750w',
            }),
          };

          const url = `${apiUrl}/${resource}/${resourceType}/${
            params.id
          }/?${fetchUtils.queryParameters(query, queryOptions)}`;

          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.data.uuid },
            id: json.data.uuid,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getOne() error:' + error?.toString());
          throw error;
        }
      },

      getMany: async (resource: string, params: any) => {
        let resourceType = 'asset';

        if (resource === 'user') {
          resourceType = 'account';
        }

        const fetchSingleId = async (id: string) => {
          const singleUrl = `${apiUrl}/${resource}/${resourceType}/${id}/`;
          const { json } = await httpClient(singleUrl, { method: 'GET' });
          return json.data;
        };

        try {
          const settledResponses = await Promise.allSettled(
            params.ids.map(fetchSingleId),
          );
          const successfulResponses = settledResponses
            .filter((response) => response.status === 'fulfilled')
            .map((response) => (response as PromiseFulfilledResult<any>).value);
          const failedResponses = settledResponses
            .filter((response) => response.status === 'rejected')
            .map((response) => (response as PromiseRejectedResult).reason);
          // Log failed responses or handle them appropriately.
          if (failedResponses.length > 0) {
            console.warn('dataProvider.getMany - Some fetches failed:', failedResponses);
          }
          // If all fetches failed, throw an error
          if (successfulResponses.length === 0) {
            throw new Error('dataProvider.getMany - All fetches failed.');
          }

          // Only return the successful responses, any failed responses won't be included.
          const results: GetManyResult = {
            data: successfulResponses.map((item: any) => ({
              ...item,
              id: item.uuid,
            })),
          };

          return results;
        } catch (error) {
          console.error('[dataProvider] getMany() error:' + error?.toString());
          throw error;
        }
      },

      getManyReference: async (resource: string, params: any) => {
        try {
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          const query = {
            ...params.filter,
            [params.target]: params.id,
            _page: page,
            _limit: perPage,
            _sort: field,
            _order: order,
          };

          const url = `${apiUrl}/${resource}/asset/?${fetchUtils.queryParameters(
            query,
            queryOptions,
          )}`;

          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const formattedResult = json.data.items.map((item: any) => ({
            ...item,
            id: item.uuid,
          }));
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getManyReference() error:' + error?.toString());
          throw error;
        }
      },

      create: async (resource: string, params: any) => {
        let baseResource = resource;
        let subResource = ''; // resource base/sub may change in the switch.

        switch (resource) {
          case 'editorial': {
            subResource = 'asset';
            break; // /editorial/asset/search/
          }
          case 'user': {
            subResource = 'account';
            break; // /user/account/search/
          }
          case 'savedsearch': {
            baseResource = 'core';
            subResource = 'savedsearch';
            break; // /core/savedsearch/search/
          }
          default: {
            console.warn('[create] - No resourceType:', resource); // Could be an error, logging warning.
            break;
          }
        }
        try {
          const url = `${apiUrl}/${baseResource}/${subResource}/`;

          const relatedData = await relatedContent(params.data);
          const transformedData = await stripTOC(relatedData);

          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(transformedData),
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.uuid },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] create() error:' + error?.toString());
          throw error;
        }
      },

      update: async (resource: string, params: any) => {
        let baseResource = resource;
        let subResource = ''; // resource base/sub may change in the switch.

        switch (resource) {
          case 'editorial': {
            subResource = 'asset';
            break; // /editorial/asset/search/
          }
          case 'user': {
            subResource = 'account';
            break; // /user/account/search/
          }
          case 'savedsearch': {
            baseResource = 'core';
            subResource = 'savedsearch';
            break; // /core/savedsearch/search/
          }
          default: {
            console.warn('[create] - No resourceType:', resource); // Could be an error, logging warning.
            break;
          }
        }
        try {
          const url = `${apiUrl}/${baseResource}/${subResource}/${params.id}/`;

          // Add the preview parameters to the data
          const transformedData = {
            ...params.data,
            preview: {
              thumbnail: '200w',
              display: '750w',
            },
          };

          // Process related content and strip TOC
          const relatedData = await relatedContent(transformedData);
          const finalData = await stripTOC(relatedData);

          const { json, status } = await httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(finalData),
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.uuid },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] update() error:' + error?.toString());
          throw error;
        }
      },

      updateMany: async (resource: string, params: any) => {
        try {
          const query = {
            filter: JSON.stringify({ id: params.ids }),
          };

          const url = `${apiUrl}/${resource}/asset/?${fetchUtils.queryParameters(
            query,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] updateMany() error:' + error?.toString());
          throw error;
        }
      },

      delete: async (resource: string, params: any) => {
        try {
          const url = `${apiUrl}/${resource}/${params.id}/`;
          const { json, status } = await httpClient(url, {
            method: 'DELETE',
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] delete() error:' + error?.toString());
          throw error;
        }
      },

      deleteMany: async (resource: string, params: any) => {
        try {
          const url = `${apiUrl}/${resource}/asset/delete/`;
          const deleteObject = { uuid: params.ids };

          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(deleteObject),
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] deleteMany() error:' + error?.toString());
          throw error;
        }
      },

      binaryUpload: async (resource: string, params: BinaryUploadParams) => {
        try {
          const url = `${apiUrl}${resource}/`;
          const formData = new FormData();
          if (params.file) {
            formData.append('file', params.file);
          }

          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: formData,
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] binaryUpload() error:' + error?.toString());
          throw error;
        }
      },

      binaryConvert: async (resource: string, params: any) => {
        try {
          const url = `${apiUrl}${resource}/`;

          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params),
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] binaryConvert() error:' + error?.toString());
          throw error;
        }
      },

      // Get user's default site
      getDefaultSite: async (): Promise<string | null> => {
        try {
          const { json, status } = await httpClient(
            `${apiUrl}/core/session/sites/default/`,
          );
          await assertResponseSuccess(json, status);

          const result: DefaultSiteResult = json;
          return result.data.domain; // Return default site from API (could be success, w/ domain: null if unset)
        } catch (error) {
          console.error('[dataProvider] getDefaultSite() error:' + error?.toString());
          throw error;
        }
      },

      // Set user's default site
      setDefaultSite: async (domain: string): Promise<string> => {
        try {
          const { json, status } = await httpClient(
            `${apiUrl}/core/session/sites/default/`,
            {
              method: 'POST',
              body: JSON.stringify({ domain: domain }),
            },
          );
          await assertResponseSuccess(json, status);

          // If the status is not 204 and no error is thrown, something went wrong
          throw new Error(`Unexpected response status: ${status}`);
        } catch (error) {
          console.error('[dataProvider] setDefaultSite() error:' + error?.toString());
          throw error;
        }
      },

      // Get default saved search by application
      getDefaultSavedSearch: async (app?: string): Promise<DefaultSavedSearchResult> => {
        try {
          const response = await httpClient(`${apiUrl}/core/savedsearch/default/${app}/`);
          const json = await response.json;
          return json; // Return the JSON payload as the default search
        } catch (error) {
          const typedError = error as any; // We need a ticket to fix this 404 behavior asap.
          // This endpoint considers 404 as a valid response as in no default search is set.
          // Return something custom to handle 404 specifically
          if (typedError?.status === 404) {
            return {
              status: 404,
              data: {
                payload: undefined,
              },
            };
          } else {
            console.error('[dataProvider] getDefaultSearch() error:', error?.toString());
            throw error; // Reduce 404 spam in console.
          }
        }
      },

      // Set default saved search by application
      setDefaultSavedSearch: async (id: string): Promise<any | null> => {
        try {
          const { json } = await httpClient(
            `${apiUrl}/core/savedsearch/default/editorial/${id}/`,
            { method: 'POST' },
          );
          await queryClient.invalidateQueries({
            queryKey: ['getDefaultSavedSearch'], // Invalidate getDefaultSavedSearch hook keys
          });
          return json;
        } catch (error) {
          console.error('[dataProvider] setDefaultSearch() error:' + error?.toString());
          throw error;
        }
      },

      // Set default saved search by application
      removeDefaultSavedSearch: async (): Promise<any | null> => {
        try {
          const { json } = await httpClient(
            `${apiUrl}/core/savedsearch/default/editorial/`,
            { method: 'DELETE' },
          );
          await queryClient.invalidateQueries({
            queryKey: ['getDefaultSavedSearch'], // Invalidate getDefaultSavedSearch hook keys
          });
          return json;
        } catch (error) {
          console.error(
            '[dataProvider] removeDefaultSavedSearch() error:' + error?.toString(),
          );
          throw error;
        }
      },

      // Get site section tree
      getSectionTree: async (
        id?: number | null | undefined,
      ): Promise<SectionTreeResult['data']> => {
        try {
          const endpoint = id
            ? `${apiUrl}/core/section/tree/?parent=${id}`
            : `${apiUrl}/core/section/tree/`;

          const { json, status } = await httpClient(endpoint);
          await assertResponseSuccess(json, status);

          const result: SectionTreeResult = json;
          return result.data; // Return section tree
        } catch (error) {
          console.error('[dataProvider] getSectionTree() error:' + error?.toString());
          throw error;
        }
      },

      // Get user's session config
      getSessionConfig: async (): Promise<SessionConfigResult> => {
        try {
          const { json, status } = await httpClient(`${apiUrl}/core/session/config/`);
          await assertResponseSuccess(json, status);

          const sessionConfig: SessionConfigResult = json.data;
          return sessionConfig;
        } catch (error) {
          console.error('[dataProvider] getSessionConfig() error:' + error?.toString());
          throw error;
        }
      },

      // Get editorial config
      getEditorialConfig: async (): Promise<GetEditorialConfigResult> => {
        try {
          const { json, status } = await httpClient(`${apiUrl}/editorial/config/`);
          await assertResponseSuccess(json, status);

          const editorialConfig: GetEditorialConfigResult = json;
          return editorialConfig;
        } catch (error) {
          console.error('[dataProvider] getEditorialConfig() error:' + error?.toString());
          throw error;
        }
      },

      // [GET] user/account/search
      getUserAccountSearch: async (
        params: UserAccountSearchParams,
      ): Promise<GetUserAccountSearchResult> => {
        try {
          const payload = {
            ...(params.query && { query: params.query }),
            ...(params.account_type && { account_type: params.account_type }),
            ...(params.account_status && { account_status: params.account_status }),
            ...(params.auth_type && { auth_type: params.auth_type }),
            ...(params.banned && { banned: params.banned }),
            ...(params.page && { page: params.page }),
            ...(params.sort && { sort: params.sort }),
            ...(params.dir && { dir: params.dir }),
            ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }), // Limit capped to 100 by API.
          };

          const url = `${apiUrl}/user/account/search/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetUserAccountSearchResult = {
            status: json.status,
            data: {
              total: json.data.total,
              next: json.data.next,
              prev: json.data.prev,
              items: json.data.items.map((item: any) => ({
                uuid: item.uuid,
                account_type: item.account_type,
                email: item.email,
                screen_name: item.screen_name,
                avatar: item.avatar,
                first_name: item.first_name,
                last_name: item.last_name,
                title: item.title,
                department: item.department,
                ...item,
              })),
            },
          };
          return result;
        } catch (error) {
          console.error(
            '[dataProvider] getUserAccountSearch() error:' + error?.toString(),
          );
          throw error;
        }
      },

      // Get user/author account summary by ID
      getUserAccountSummaryById: async (
        uuid: string,
      ): Promise<GetUserAccountByIdResult> => {
        try {
          const url = `${apiUrl}/user/account/${uuid}/summary/`;

          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetUserAccountByIdResult = {
            status: json.status,
            data: {
              uuid: json.data.uuid,
              account_type: json.data.account_type,
              email: json.data.email,
              screen_name: json.data.screen_name,
              avatar: json.data.avatar,
              first_name: json.data.first_name,
              last_name: json.data.last_name,
              title: json.data.title,
              department: json.data.department,
              ...json.data,
            },
          };
          return result;
        } catch (error) {
          console.error(
            '[dataProvider] getUserAccountSummaryById() error:' + error?.toString(),
          );
          throw error;
        }
      },

      // Get all site workflows
      getWorkflows: async (): Promise<GetWorkflowsResult> => {
        try {
          const url = `${apiUrl}/core/workflow/`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);
          return json.data;
        } catch (error) {
          console.error('[dataProvider] getWorkflows() error:' + error?.toString());
          throw error;
        }
      },

      // Retrieve a workflow
      getWorkflowById: async (workflowId: string): Promise<GetWorkflowByIdResult> => {
        try {
          const url = `${apiUrl}/core/workflow/${workflowId}/`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);
          return json.data;
        } catch (error) {
          console.error('[dataProvider] getWorkflowById() error:' + error?.toString());
          throw error;
        }
      },

      // Get the group names the active user is assigned to
      getActiveUserGroups: async (): Promise<UserAccountGroups> => {
        try {
          const url = `${apiUrl}/user/me/groups/`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);
          return json.data;
        } catch (error) {
          console.error(
            '[getActiveUserGroups] getActiveUserGroups() error:' + error?.toString(),
          );
          throw error;
        }
      },

      // Retrieve a light summary of the active user's account
      getActiveUserSummary: async (): Promise<UserAccountSummary> => {
        try {
          const { json, status } = await httpClient(`${apiUrl}/user/me/summary/`, {
            method: 'GET',
          });
          await assertResponseSuccess(json, status);

          const result: UserAccountSummary = {
            uuid: json.data.uuid,
            account_type: json.data.account_type,
            email: json.data.email,
            screen_name: json.data.screen_name,
            avatar: json.data.avatar,
            first_name: json.data.first_name,
            last_name: json.data.last_name,
            title: json.data.title,
            department: json.data.department,
            subscription: json.data.subscription,
            ...json.data,
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getUserById() error:' + error?.toString());
          throw error;
        }
      },

      // User: Send instant login email
      sendInstantLogin: async (uuid: string, params?: any) => {
        try {
          const url = `${apiUrl}/user/account/${uuid}/instant/`;
          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params),
          });
          await assertResponseSuccess(json, status);

          return json;
        } catch (error) {
          console.error('[dataProvider] sendInstantLogin() error:' + error?.toString());
          throw error;
        }
      },

      // User: Send password reset email
      sendPasswordReset: async (uuid: string, params?: any) => {
        try {
          const url = `${apiUrl}/user/account/${uuid}/auth/password/reset/`;
          const response = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params),
          });
          await assertResponseSuccess(response.json, response.status);

          return response;
        } catch (error) {
          console.error('[dataProvider] sendPasswordReset() error:' + error?.toString());
          throw error;
        }
      },

      // User: Set password
      setPassword: async (uuid: string, params?: any) => {
        try {
          const url = `${apiUrl}/user/account/${uuid}/auth/password/`;
          const response = await httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(params),
          });
          await assertResponseSuccess(response.json, response.status);

          return response;
        } catch (error) {
          console.error('[dataProvider] updatePassword() error:' + error?.toString());
          throw error;
        }
      },

      // User: check availability of screen_name or email
      checkAvailability: async (
        name: 'screen_name' | 'email',
        value: string,
      ): Promise<AvailabilityResponse> => {
        try {
          const url = `${apiUrl}/user/account/available/${name}/?${name}=${value}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            status: json.status,
            available: json.data.available,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] checkAvailability() error:' + error?.toString());
          return {
            status: 'error',
          };
        }
      },

      // Get keywords list
      getKeywordList: async (
        params: KeywordListSearchParams,
      ): Promise<GetKeywordListResult> => {
        try {
          const payload = {
            ...(params.query && { query: params.query }),
            ...(params.page && { page: params.page }),
            ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
          };
          const url = `${apiUrl}/core/keyword/list/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetKeywordListResult = {
            status: json.status,
            data: {
              total: json.data.total,
              next: json.data.next,
              prev: json.data.prev,
              items: json.data.items.map((item: KeywordListItem) => ({
                ...item,
              })),
            },
          };
          return result;
        } catch (error) {
          console.error('dataProvider.getKeywordList - error:', error);
          throw error;
        }
      },

      // Get section list
      getSectionList: async (
        params: SectionListSearchParams,
      ): Promise<GetSectionListResult> => {
        try {
          const payload = {
            ...(params.query && { query: params.query }),
            ...(params.page && { page: params.page }),
            ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
          };
          const url = `${apiUrl}/core/section/list/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetSectionListResult = {
            status: json.status,
            data: {
              total: json.data.total,
              next: json.data.next,
              prev: json.data.prev,
              items: json.data.items.map((item: SectionListItem) => ({
                ...item,
              })),
            },
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getSectionList() error:' + error?.toString());
          throw error;
        }
      },

      // Get site access list
      getSites: async (params: GetSitesParams): Promise<GetSitesResult> => {
        try {
          const payload = {
            ...(params.query && { query: params.query }),
            ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
            ...(params.page && { page: params.page }),
          };
          const url = `${apiUrl}/core/session/sites/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetSitesResult = {
            status: json.status,
            data: {
              total: json.data.total,
              next: json.data.next,
              prev: json.data.prev,
              items: json.data.items.map((item: SiteItem) => ({ ...item })),
            },
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getSites() error:' + error?.toString());
          throw error;
        }
      },

      // Get site subscription services
      getServices: async (params: GetServicesParams): Promise<GetServicesResult> => {
        try {
          const payload = {
            ...(params.title && { title: params.title }),
            ...(params.query && { query: params.query }),
            ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
            ...(params.page && { page: params.page }),
          };
          const url = `${apiUrl}/subscription/services/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetServicesResult = {
            status: json.status,
            data: {
              total: json.data.total,
              next: json.data.next,
              prev: json.data.prev,
              items: json.data.items.map((item: ServiceItem) => ({ ...item })),
            },
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getServices() error:' + error?.toString());
          throw error;
        }
      },

      // Get site subscription service types
      getServiceTypes: async (
        params: GetServiceTypesParams,
      ): Promise<GetServiceTypesResult> => {
        try {
          const payload = {
            ...(params.activated && { activated: params.activated }),
            ...(params.available && { available: params.available }),
            ...(params.query && { query: params.query }),
            ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
            ...(params.page && { page: params.page }),
          };
          const url = `${apiUrl}/subscription/services/types/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetServiceTypesResult = {
            status: json.status,
            data: {
              total: json.data.total,
              next: json.data.next,
              prev: json.data.prev,
              items: json.data.items.map((item: ServiceTypeItem) => ({ ...item })),
            },
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getServices() error:' + error?.toString());
          throw error;
        }
      },

      // Editorial semantic analysis
      semanticAnalysis: async ({
        request,
      }: {
        request: AnalysisRequest;
      }): Promise<AnalysisResponse> => {
        try {
          const url = `${apiUrl}/editorial/semantic/analyze/`;

          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          await assertResponseSuccess(json, status);

          const responseData = json;
          const keywords: KeywordSuggestion[] = responseData.data.keywords.map(
            (keywordData: any) => ({
              tag: keywordData.tag,
              score: keywordData.score,
            }),
          );
          const response: AnalysisResponse = {
            status: 'success',
            data: {
              keywords: keywords,
              categories: responseData.data.categories || [],
              iptc: responseData.data.iptc || [],
              iab: responseData.data.iab || [],
            },
          };
          return response;
        } catch (error) {
          console.error('[dataProvider] analyzeData() error:', error?.toString());
          throw error;
        }
      },

      // Open AI chat
      openAIChat: async ({ messages }: ChatQueryParams): Promise<ChatQueryResult> => {
        try {
          const payload: ChatRequest = {
            messages: messages,
          };
          const url = `${apiUrl}/core/openai/chat/`;
          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          await assertResponseSuccess(json, status);

          const responseData = json;
          const result: ChatQueryResult = {
            status: 'success',
            data: responseData.choices[0].message.content,
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] chatWithOpenAI() error:', error?.toString());
          throw error;
        }
      },

      // Query the Omnibar AI API Endpoint
      getOmnibarGPTQuery: async ({
        query,
      }: OmnibarGPTQueryParams): Promise<OmnibarGPTQueryResult> => {
        try {
          const payload = {
            ...(query && { query: query }),
          };
          const url = `${apiUrl}/core/openai/omnibar/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: OmnibarGPTQueryResult = {
            status: json.status,
            data: {
              function: json.data.function,
              args: json.data.args,
            },
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getOmnibarGPTQuery() error:' + error?.toString());
          throw error;
        }
      },

      // Get /user/group/names/
      getUserGroupNames: async (): Promise<string[] | null> => {
        try {
          const { json, status } = await httpClient(`${apiUrl}/user/group/names/`);
          await assertResponseSuccess(json, status);

          const groupNames: string[] = Object.values(json.data || {}).filter(
            (value): value is string => typeof value === 'string',
          );
          return groupNames;
        } catch (error) {
          console.error('[dataProvider] getUserGroupNames() error:' + error?.toString());
          throw error;
        }
      },

      // Update /user/admin/{id}/groups/
      updateUserGroupsById: async (uuid: string, groups: string[]) => {
        try {
          const url = `${apiUrl}/user/admin/${uuid}/groups/?${fetchUtils.queryParameters(
            queryOptions,
          )}`;
          const requestBody = JSON.stringify(groups);
          const { json, status } = await httpClient(url, {
            method: 'PUT',
            body: requestBody,
          });
          await assertResponseSuccess(json, status);

          const result: UpdateUserGroupsByIdResult = {
            status: json.status,
            data: json.data,
          };
          return result;
        } catch (error) {
          console.error(
            '[updateUserGroupsById] updateUserGroupsById() error:' + error?.toString(),
          );
          throw error;
        }
      },

      // Get /user/admin/{id}/groups/
      getUserGroupsById: async ({
        uuid,
      }: GetUserGroupsByIdParams): Promise<GetUserGroupsByIdResult> => {
        try {
          const url = `${apiUrl}/user/admin/${uuid}/groups/?${fetchUtils.queryParameters(
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetUserGroupsByIdResult = {
            status: json.status,
            data: json.data,
          };
          return result;
        } catch (error) {
          console.error(
            '[getUserGroupsById] getUserGroupsById() error:' + error?.toString(),
          );
          throw error;
        }
      },

      // Get /subscription/members/{id}/subscriptions
      // Get /subscription/members/{id}/subscriptions
      getUserSubscriptionsById: async ({
        uuid,
        page,
        limit,
      }: GetUserSubscriptionsByIdParams): Promise<GetUserSubscriptionsByIdResult> => {
        try {
          const payload = {
            ...(page && { page: page }),
            ...(limit && { limit: limit > 100 ? 99 : limit }),
          };
          const url = `${apiUrl}/subscription/members/${uuid}/subscriptions/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: GetUserSubscriptionsByIdResult = {
            status: json.status,
            data: { ...json.data },
          };
          return result;
        } catch (error) {
          console.error(
            '[getUserSubscriptionsById] getUserSubscriptionsById() error:' +
              error?.toString(),
          );
          throw error;
        }
      },

      // Dashboard.
      // TODO: need to pass the entire TDashboardWidgetConfig instead of the id, based on the new API struct.
      getDashboardNodeById: async ({
        id,
        node = 'dashboard',
        isFetchingWidgetConfig = false,
        isRss = false,
      }: GetDashboardByIdParams): Promise<
        TGetDashboardByIdResponse | TGetWidgetByIdResponse | undefined
      > => {
        try {
          const isWidget: boolean = node === 'widget';
          const fetchWidgetDataFilter: string[] = [independentSystemWidgetIds.help];
          const fetchModuleFilter: string[] = [independentSystemWidgetIds.status];
          const url: string = `${apiUrl}/insights/${node}/${id}/`;

          // Fetch dashboard only.
          if (!isWidget) {
            try {
              const { json, status } = await httpClient(url, { method: 'GET' });
              await assertResponseSuccess(json, status);

              return {
                status: json.status,
                data: json.data,
              };
            } catch (error) {
              console.error('[getDashboardNodeById] error:' + error?.toString());
              throw error;
            }
          }

          // Fetch module (template/config) and widget data.
          if (isWidget && !fetchWidgetDataFilter.includes(id)) {
            const endpoints = [
              // Widget data for charts.
              await httpClient(`${url}data/`, { method: 'GET' }),

              // Module. Only fetch if not filtering system widgets.
              ...(!fetchModuleFilter.includes(id)
                ? [
                    await httpClient(
                      `${apiUrl}/insights/module/${isRss ? 'core.rss' : id}/`,
                      {
                        method: 'GET',
                      },
                    ),
                  ]
                : []),

              // Widget config. Only fetch if requested.
              ...(isFetchingWidgetConfig
                ? [await httpClient(`${url}widget/${id}`, { method: 'GET' })]
                : []),
            ];

            // Touch all endpoints.
            const [widgetResults, module, widgetConfig] = await Promise.all(endpoints);

            // Destructure responses and handle errors.
            const { json: widgetJson, status: widgetStatus } = widgetResults;
            await assertResponseSuccess(widgetJson, widgetStatus);

            if (module) {
              const { json: moduleJson, status: moduleStatus } = module;
              await assertResponseSuccess(moduleJson, moduleStatus);
            }

            if (widgetConfig) {
              const { json: widgetConfigJson, status: widgetConfigStatus } = widgetConfig;
              await assertResponseSuccess(widgetConfigJson, widgetConfigStatus);
            }

            // Return consolidated data.
            return {
              widget: widgetResults.json.data,
              ...(!fetchModuleFilter.includes(id) && { module: module.json.data }),
              ...(isFetchingWidgetConfig && {
                widgetConfig: widgetConfig.json.data,
              }),
            };
          }
        } catch (error) {
          console.error(
            `[dataProvider] getDashboardNodeById() - (ID-Node): ${id}-${node} error:` +
            error?.toString(),
          );
          throw error;
        }
      },

      getDashboardWidgetData: async (
        widgetId: string,
      ): Promise<TDashboardWidgetDataResults | undefined> => {
        try {
          console.log('widgetId', widgetId);
          const dataUrl = `${apiUrl}/insights/widget/${widgetId}/data/`;

          const { json } = await httpClient(dataUrl, {
            method: 'GET',
          });

          return json.data;
        } catch (error) {
          console.error(
            `[dataProvider] getDashboardWidgetData() - (ID-Node): ${widgetId} error:` +
              error?.toString(),
          );
          throw error;
        }
      },

      // Returns all RSS widgets for the current dashboard.
      // What is returned has to be mapped to the dashboard layout property.
      getAllDashboardRssWidgetModules: async (appId: string, widgetId: string) => {
        try {
          const moduleUrl = new URL(`${apiUrl}/insights/module/`);
          moduleUrl.searchParams.append('app', appId);
          moduleUrl.searchParams.append('module', 'rss');
          moduleUrl.searchParams.append('limit', '5');

          const { json } = await httpClient(moduleUrl.toString(), {
            method: 'GET',
          });

          return json.data;
        } catch (error) {
          console.error(
            `[dataProvider] getAllDashboardRssWidgets() - (ID-Node): ${widgetId} error:` +
              error?.toString(),
          );
          throw error;
        }
      },

      // Get /dmp/audiences/{id}/
      getAudienceById: async (id: string): Promise<GetAudienceByIdResult> => {
        try {
          const url = `${apiUrl}/dmp/audiences/${id}/`;
          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);
          const result: GetAudienceByIdResult = {
            status: json.status,
            data: { ...json.data },
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getAudienceById() error:' + error?.toString());
          throw error;
        }
      },

      // Update the function to fetch the asset preview
      getAssetPreview: async (id: string): Promise<GetAssetPreviewResult> => {
        try {
          const url = `${apiUrl}/core/preview/asset/editorial/${id}/`;
          const { status, body, json } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);
          const result: GetAssetPreviewResult = {
            status: 'success',
            data: { [id]: { body: body } }, // Key-value pair using the id as the key
          };
          return result;
        } catch (error) {
          console.error('[dataProvider] getAssetPreview() error:' + error?.toString());
          throw error;
        }
      },

      getUserWalletData: async (
        uuid: string,
        { nextToken, limit }: { nextToken?: string | null; limit: number },
      ): Promise<UserWallet> => {
        try {
          const url = new URL(`${apiUrl}/pay/wallet/${uuid}/search/`);
          if (nextToken) {
            url.searchParams.append('page', nextToken);
          }
          url.searchParams.append('limit', limit.toString());

          const { json, status } = await httpClient(url.toString(), { method: 'GET' });
          await assertResponseSuccess(json, status);

          const result: UserWallet = json.data;
          return result;
        } catch (error) {
          console.error('[dataProvider] getUserWallet() error:' + error?.toString());
          throw error;
        }
      },

      addPaymentMethod: async (uuid: string, params: any): Promise<UserWallet> => {
        try {
          const url = `${apiUrl}/pay/wallet/${uuid}/payment/`;
          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params),
          });

          await assertResponseSuccess(json, status);

          const result: UserWallet = json.data;
          return result;
        } catch (error) {
          console.error(
            '[dataProvider] addUserWalletPaymentMethod() error:' + error?.toString(),
          );
          throw error;
        }
      },

      removePaymentMethod: async (uuid: string, paymentId: string): Promise<void> => {
        try {
          const url = `${apiUrl}/pay/wallet/${uuid}/payment/${paymentId}/`;
          const response = await httpClient(url, { method: 'DELETE' });

          await assertResponseSuccess(response?.json, response?.status);

          const result = Promise.resolve();
          return result;
        } catch (error) {
          console.error(
            '[dataProvider] removePaymentMethod() error:' + error?.toString(),
          );
          throw error;
        }
      },

      updateUserData: async (uuid: string, params: any): Promise<UserAccountSummary> => {
        try {
          const url = `${apiUrl}/user/account/${uuid}/`;
          const { json, status } = await httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(params),
          });

          await assertResponseSuccess(json, status);

          const result: UserAccountSummary = {
            uuid: json.data.uuid,
            account_type: json.data.account_type,
            email: json.data.email,
            screen_name: json.data.screen_name,
            avatar: json.data.avatar,
            first_name: json.data.first_name,
            last_name: json.data.last_name,
            title: json.data.title,
            department: json.data.department,
            subscription: json.data.subscription,
            ...json.data,
          };

          return result;
        } catch (error) {
          console.error('[dataProvider] updateUserData() error:' + error?.toString());
          throw error;
        }
      },

      editPaymentMethod: async (
        uuid: string,
        paymentId: string,
        params: any,
      ): Promise<void> => {
        try {
          const url = `${apiUrl}/pay/wallet/${uuid}/payment/${paymentId}/`;
          const response = await httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(params),
          });

          await assertResponseSuccess(response?.json, response?.status);

          const result = Promise.resolve();
          return result;
        } catch (error) {
          console.error('[dataProvider] editPaymentMethod() error:' + error?.toString());
          throw error;
        }
      },
    };

    // -----------------------------------------------
    // ---------------- AUTH PROVIDER ----------------
    // -----------------------------------------------
    const authProvider: AuthProvider = {
      // Note: CosmosAuthEnvContext / WithAuthChecks contains an useEffect which
      // handles redirecting to login page when not authenticated.
      // The 'login' here is likely unused, but React-Admin needs it.
      // The 'logout' is used on the logout button, but redirecting post logout is handled by the effect.

      login: async () => {
        const redirect_uri = window.location.origin;
        const nav_to_cosmos_route = window.location.href.replace(
          window.location.origin,
          '',
        );
        try {
          await auth.signinRedirect({
            redirect_uri: redirect_uri,
            state: { nav_to_cosmos_route: nav_to_cosmos_route },
          });
          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] login() error:' + error?.toString());
          return Promise.reject(error);
        }
      },

      logout: async (params) => {
        try {
          // await auth.signoutRedirect({/* TNCMS-554796 */}); (?)
          // ^^ This will likely be used once we get the official endpoint. TNCMS-554796
          // For now we manually kill the token and hit the existing endpoint ourselves.
          const response = await fetch(`${envState?.OIDC_SERVER_URL}api/-/logout`, {
            method: 'POST',
            credentials: 'include',
          });

          if (!response.ok) {
            throw new Error(
              'fetch/POST logout on the server response not ok.' +
                JSON.stringify(response, null, 2),
            );
          }

          const json = await response.json();

          if (!json.success) {
            throw new Error(
              'Failed to logout on the server?' + JSON.stringify(json, null, 2),
            );
          }

          // Response successful, now we can logout on the client.
          await auth.clearStaleState();
          await auth.removeUser();

          // Cleanup react-query client, about to nav off site to oidc login page.
          queryClient.clear();
          await queryClient.resetQueries();

          // Also clear session storage for extra safety.
          sessionStorage.clear();

          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] logout() error:' + error?.toString());
          // (partial failsafe) - If we fail to logout on the server, we still could still try to 'logout' on the client, clearing the token.
          queryClient.clear();
          await queryClient.resetQueries();
          await auth.clearStaleState();
          await auth.removeUser();
          sessionStorage.clear();
          return Promise.reject(error);
        }
      },

      checkAuth: async () => {
        try {
          if (!auth.user) {
            throw new Error('User not found while checking auth.');
          }
          if (!auth.isAuthenticated) {
            throw new Error('User unauthenticated.');
          }
          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] checkAuth() error:' + error?.toString());
          return Promise.reject(error);
        }
      },

      getIdentity: async (): Promise<UserIdentity> => {
        try {
          if (!auth.user) {
            throw new Error('User not found while getting identity.');
          }
          if (!auth.isAuthenticated) {
            throw new Error('User unauthenticated.');
          }
          if (!auth.user.profile.email) {
            throw new Error('User profile email not found while getting identity.');
          }
          const gravatarIconUrl = gravatarUrl(auth.user.profile.email, {
            size: 64,
            default: 'mm',
          });
          return Promise.resolve({
            id: String(auth.user.profile.email),
            avatar: gravatarIconUrl,
            uuid: auth?.user?.profile?.sub ?? '',
          });
        } catch (error) {
          console.error('[authProvider] getIdentity() Error :' + error?.toString());
          return Promise.reject(error);
        }
      },

      checkError: async (error: any) => {
        try {
          const status = error.status || error.response?.status;
          if (auth.error || status === 401 || status === 403) {
            console.error('Status 401 / 403 / Auth Error calling logout...', status);
            await authProvider.logout(error);
            return Promise.reject();
          }
          // For other errors (e.g. 404, etc...), resolve the promise to indicate no auth problem.
          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] checkError() error:' + error?.toString());
          return Promise.reject(error);
        }
      },

      getPermissions: async () => {
        return Promise.resolve(/* Permission handling stub */);
      },

      handleCallback: async () => {
        /**
         * React-admin provides a default callback URL at /auth-callback.
         * (we've disabled this on our <Admin /> component as we should handle that in our own AuthEnvContext)
         * This route calls the authProvider.handleCallback method on mount.
         * This means its the authProvider job to use the params received
         * from the callback URL to authenticate future API calls.
         */
        Promise.resolve(/* - likely unused, stubbed in - */);
      },
    };

    return {
      dataProvider: dataProvider,
      authProvider: authProvider,
    };
  }, [auth, envState, queryClient, apiUrl, httpClient]);

  if (
    !defaultSite ||
    !auth ||
    !auth?.user?.token_type ||
    !auth?.user?.access_token ||
    !envState?.API_SERVER_URL ||
    !envState?.OIDC_SERVER_URL ||
    !queryClient
  ) {
    return <BloxLoadingSplash message="Building providers..." />;
  }

  return (
    <CosmosRAProvidersContext.Provider value={contextValue}>
      {children}
    </CosmosRAProvidersContext.Provider>
  );
};

/**
 * File Notes:
 *
 * --- Message ---
 * (pre-hooks/component version) - RA Correspondence on withLifecycleCallbacks + custom methods in dataprovider:
 *
 * Response:
 * withLifecycleCallbacks seems to only accept a basic dataProvider
 * (in the sense of without any custom methods) and returns a basic dataProvider as well.
 * However this is just a limitation of the TypeScript type currently in use, in fact you can
 * also provide an augmented dataProvider (including custom methods) and it will work too.
 *
 * withLifecycleCallbacks is only meant to override standard react-admin methods (getOne, getList, ...) so we
 * figured typing the base dataProvider as DataProvider was enough, but your question makes me
 * realize we could add support for generic types there.
 *
 * Anyways, TLDR is, the code would work both ways (regardless of what TypeScript says),
 * and the approach you described is perfectly valid.
 * Cheers, Jean-Baptiste, React Admin Support Team
 *
 *
 * --- Message ---
 * RA Correspondence on adding custom lifecycle callbacks to our custom methods, if ever needed.
 *
 * To achieve that, you can take a look at withLifecycleCallbacks() definition
 * in ra-core/src/dataProvider/withLifecycleCallbacks.ts and take inspiration from the ResourceCallbacks[] type.
 *
 * --- Here is an implementation example ---
 * const baseDataProvider = simpleRestProvider("http://path.to.my.api/");
 * const transformData = (data: any[]) => {
 *   return data; // do something with data
 * };
 *
 * interface CustomResourceCallbacks extends ResourceCallbacks {
 *   binaryUpload?: (params: any) => Promise<any>;
 *   binaryConvert?: (params: any) => Promise<any>;
 * }
 *
 * // override withLifecycleCallbacks definition
 * const customWithLifecycleCallbacks = (
 *   dataProvider: DataProvider,
 *   handlers: CustomResourceCallbacks[]
 * ) => withLifecycleCallbacks(dataProvider, handlers);
 *
 * export const dataProvider = customWithLifecycleCallbacks(baseDataProvider, [
 *   {
 *     resource: "posts",
 *     afterGetManyReference: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     afterGetList: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     binaryUpload: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *     binaryConvert: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *   },
 * ]);
 *
 */
