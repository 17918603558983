import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { parse } from 'query-string';
import { SetValueConfig } from 'react-hook-form/dist/types/form';
import { omitBy } from 'remeda';
import { stripHtml } from 'string-strip-html';
import { AnyRecord, AssetType } from './types/cosmosTypes';
import {
  EditorialAssetSearchResultItem,
  EditorialSearchQueryParams,
  RelatedAsset,
  UserAccountSummary,
} from './types/dataProvider';
import { Theme } from '@mui/material/styles';

dayjs.extend(relativeTime); // Enable relative time plugin for dayjs

/**
 * Capitalizes the first letter of a given string and leaves the rest of the string unchanged.
 *
 * @param {string} str - The input string to capitalize.
 * @returns {string} The modified string with the first letter capitalized.
 */

export function capitalizeFirstLetter(str: string): string {
  const stringToTransform = String(str);
  return stringToTransform.charAt(0).toUpperCase() + stringToTransform.slice(1);
}

export interface DebounceOptions {
  /** If true, the function will be called on the leading edge of the timeout. Default is false. */
  leading?: boolean;
}

/**
 * Debounces a function, ensuring that it's only called once after the specified delay.
 * @param func - The function to debounce.
 * @param delay - The amount of time in milliseconds to wait before invoking the function.
 * @param options - Optional configuration for the debounce behavior.
 * @returns A debounced version of the specified function.
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
  options: DebounceOptions = {},
): (...funcArgs: Parameters<T>) => void {
  let timerId: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<T>) => {
    if (!timerId && options.leading) {
      func(...args);
    }
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => func(...args), delay);
  };
}

/**
 * Deeply merges two objects, combining their properties recursively.
 * If a property exists in both objects and is itself an object, the function will merge those objects.
 * Otherwise, the value from the secon object will overwrite the value in the first object.
 *
 * @param {Record<string, any>} obj1 - The first object to merge.
 * @param {Record<string, any>} obj2 - The second object to merge. Properties in this object will overwrite corresponding properties in the first object.
 * @returns {Record<string, any>} The merged object, containing all properties from both input objects.
 *
 * @example
 * const objA = { a: 1, b: { x: 10 } };
 * const objB = { b: { y: 20 }, c: 3 };
 * const mergedObj = deepMerge(objA, objB);
 * // mergedObj: { a: 1, b: { x: 10, y: 20 }, c: 3 }
 */
export function deepMerge(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): Record<string, any> {
  const output: Record<string, any> = { ...obj1 };
  if (
    typeof obj1 === 'object' &&
    obj1 !== null &&
    typeof obj2 === 'object' &&
    obj2 !== null
  ) {
    Object.keys(obj2).forEach((key) => {
      if (obj2[key] && typeof obj2[key] === 'object') {
        if (!(key in obj1)) {
          Object.assign(output, { [key]: obj2[key] });
        } else {
          output[key] = deepMerge(obj1[key], obj2[key]);
        }
      } else {
        Object.assign(output, { [key]: obj2[key] });
      }
    });
  }
  return output;
}

/**
 * Formats a date string based on the provided formatting options.
 * If the relative flag is true, it returns "Today" for the current date, "Yesterday" for the previous day, and the formatted date for other dates.
 *
 * @param {string} dateString - The input date string to be formatted.
 * @param {Intl.DateTimeFormatOptions} [options] - Optional configuration for the date formatting. If not provided, it defaults to: `{ day: '2-digit', month: 'short', year: 'numeric' }`.
 * @param {boolean} [relative] - Flag to indicate if relative formatting should be applied.
 * @returns {string} The formatted date string.
 *
 * @example
 * const date = "2023-10-23";
 * const formattedDate = formatDate(date);
 * // formattedDate: "Oct 23, 2023"
 */
export const formatDate = (dateString: string, options?: Intl.DateTimeFormatOptions, relative?: boolean): string => {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };

  const finalOptions = options || defaultOptions;

  // Check that the date we're formatting is a valid date
  if (!dayjs(dateString).isValid()) {
    return ''; // Return an empty string if the date is invalid
  }

  const date = dayjs(dateString);
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');

  const timeOptions = {
    hour: finalOptions.hour,
    minute: finalOptions.minute,
    second: finalOptions.second,
    hour12: finalOptions.hour12,
    timeZone: finalOptions.timeZone,
    timeZoneName: finalOptions.timeZoneName,
  };

  const hasTimeOptions = Object.values(timeOptions).some(option => option !== undefined);

  if (relative) {
    if (date.isSame(today, 'day')) {
      return hasTimeOptions ? `${new Intl.DateTimeFormat('en-US', timeOptions).format(date.toDate())} Today` : 'Today';
    }
    if (date.isSame(yesterday, 'day')) {
      return hasTimeOptions ? `${new Intl.DateTimeFormat('en-US', timeOptions).format(date.toDate())} Yesterday` : 'Yesterday';
    }
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', finalOptions).format(
    date.toDate(),
  );

  return formattedDate;
};

/**
 * Formats a date string based on the provided formatting options.
 * It allows formatting dates without available timezone information.
 *
 * @param {string} dateString - The input date string to be formatted.
 * @param {Intl.DateTimeFormatOptions} options - Optional configuration for the date formatting.
 * @returns {string} The formatted date string.
 *
 * @example
 * const date = "2023-10-23";
 * const formattedDate = formatBasicDate(date, { month: 'short', year: 'numeric' });
 * // formattedDate: "Oct 2023"
 */
export const formatBasicDate = (
  dateString: string,
  options: Intl.DateTimeFormatOptions,
) => {
  // Split the date string into year, month, and day parts
  const [year, month, day] = dateString.split('-').map((part) => parseInt(part, 10)); // Parse strings to integers
  const formattedParts: string[] = [];
  if (options?.month) {
    const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
      new Date(year, month - 1, 1),
    ); // Adjust month index
    formattedParts.push(formattedMonth);
  }
  if (options?.year) {
    formattedParts.push(year.toString());
  }
  if (options?.day) {
    formattedParts.push(day.toString());
  }
  return formattedParts.join(' ').trim();
};

/**
 * Checks if a given asset type has an editor that is enabled.
 *
 * @param {checkType} checkType - String to check. e.g. 'article', 'image', etc.
 * @returns {boolean} True if the asset type is in the list of enabled types; false otherwise.
 *
 * @example
 * // Returns true if 'article' is in the list of enabled asset editor types
 * isAssetTypeEditorEnabled('article');
 */

export const editorEnabledAssetTypes: AssetType[] = [
  'article',
  'image',
  'file',
  'audio',
  'html',
  'table',
  'collection',
  'link',
  'poll',
  'video',
];

export const isAssetTypeEditorEnabled = (checkType: any): boolean => {
  if (typeof checkType !== 'string') {
    return false;
  }
  const checkTypeLowercased = checkType.toLowerCase();
  return editorEnabledAssetTypes.some((type) => type === checkTypeLowercased);
};

/** Right Drawer Enabled Asset Types */
export const rightDrawerEnabledAssets: AssetType[] = ['article', 'collection'];

/**
 * Removes query parameters from a URL string.
 * @param urlString - The URL string to remove query parameters from.
 * @returns The URL string without any query parameters.
 * Console logs an error if the input is not a string or if the URL cannot be parsed and returns the original string.
 */
export const removeQueryParamsFromUrlString = (urlString: string | undefined) => {
  if (typeof urlString !== 'string') {
    console.warn(`Expected string but got ${urlString} typeof (${typeof urlString})`);
    return urlString; // Guard against non string values
  }
  try {
    if (urlString.startsWith('blob:') || urlString.startsWith('<iframe') || urlString.startsWith('<video')) {
      return urlString; // Blobs, iframes, and video HTML tags shouldnt have query params...
    }
    let parsedUrl = new URL(urlString); // Parse the URL, this can throw.
    return `${parsedUrl.origin}${parsedUrl.pathname}`; // Construct and return the URL without query parameters
  } catch (error) {
    console.error(
      '[removeQueryParamsFromUrlString] Unsuccessful, returning original string:',
      urlString,
    );
    return urlString; // Return the original string if we can't parse it.
  }
};

/**
 * Checks if a given URL string is a valid URL. Optionally checks if the URL's protocol
 * is among the allowed protocols if a list is provided.
 *
 * @param urlString - The URL string to check.
 * @param protocols - Optional array of protocols to check against, e.g., ['http:', 'https:']
 * @param baseString - Optional base URL to use for resolving the URL when urlString is relative.
 * @returns True if the URL is valid; if protocols are provided, true only if the URL's protocol matches one of the specified protocols.
 * @example isValidUrl('http://example.com', ['http:', 'https:']) { ... }
 */
export const isValidUrl = (
  urlString: string,
  protocols?: string[],
  baseString?: string,
): boolean => {
  try {
    const url = new URL(urlString, baseString);
    if (protocols && protocols.length) {
      return protocols.includes(url.protocol);
    }
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Retrieves a nested value from an object based on a specified path.
 *
 * @param obj - The object to traverse.
 * @param path - String representation of the path to the desired value.
 * @returns {any} - Nested value or undefined if not found.
 */
export const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path.split('.').reduce((acc: any, part: string) => acc && acc[part], obj);
};

/**
 * Returns the route from a given path, removing the domain.
 *
 * @param path
 * @returns Route
 */
export const getRoute = (path: string) => {
  const pathSections = path.split('/').filter((section) => section !== '');
  // Remove the first section (domain)
  pathSections.shift();
  // Join the remaining sections back together
  const route = '/' + pathSections.join('/');
  return route;
};

/**
 * Extracts the site/default site part from a given pathname.
 *
 * @param {string} pathname - The pathname to extract the site/default site part from.
 * @returns {string} The extracted site/default site part.
 */
export const getDomainFromPath = (pathname: string): string => {
  // Assuming the site/default site part is the first segment of the pathname
  const segments = pathname.split('/');
  if (segments.length >= 2) {
    return segments[1]; // Adjust the index based on the actual structure of your path
  }
  return '';
};

/**
 * Converts a string to title case.
 * @param {string} str - The input string.
 * @returns {string} - The title case string.
 */
export const toTitleCase = (str: string): string => {
  // Skip conjunctions, articles, and prepositions
  const skipWords = new Set([
    'and',
    'as',
    'but',
    'for',
    'if',
    'nor',
    'or',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'at',
    'by',
    'in',
    'of',
    'off',
    'on',
    'per',
    'to',
    'up',
    'via',
  ]);
  // More rules could likely be added (also internationalization is not considered here)

  let result = ''; // Initialize an empty string to store the result

  // Split the string into words
  const words = str.split(/\b/);

  // Convert the first word to uppercase and append it to the result
  if (words.length > 0 && /^\w+$/.test(words[0])) {
    result += words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  } else {
    result += words[0]; // Keep non-word parts unchanged
  }

  // Process the rest of the words
  for (let i = 1; i < words.length; i++) {
    const part = words[i];
    if (/^\w+$/.test(part)) {
      // Convert to uppercase if not in skipWords set
      result += skipWords.has(part.toLowerCase())
        ? part.toLowerCase()
        : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    } else {
      // Keep non-word parts unchanged
      result += part;
    }
  }

  // Ensure the final word is capitalized
  if (words.length > 1 && /^\w+$/.test(words[words.length - 1])) {
    result =
      result.slice(0, -words[words.length - 1].length) +
      words[words.length - 1].charAt(0).toUpperCase() +
      words[words.length - 1].slice(1).toLowerCase();
  }
  return result;
};

/**
 * Updates the document title based on the current route and active Blox domain.
 *
 * @param record - The record to use for the title.
 * @param assetType - The asset type to use for the title.
 */
export const updateDocumentTitle = (record?: any, assetType?: string) => {
  let title = '';
  const stinger = 'BLOX CMS';
  const activeBloxDomain = getDomainFromPath(window.location.pathname);
  const route = getRoute(window.location.pathname);

  if (!assetType) {
    const queryParams = parse(window.location.search);
    const rawAssetType = queryParams.type;
    assetType =
      rawAssetType && typeof rawAssetType === 'string'
        ? toTitleCase(rawAssetType)
        : 'Asset';
  } else {
    assetType = toTitleCase(assetType);
  }

  switch (true) {
    case route === '/' || route === '':
      title = `Dashboard - ${activeBloxDomain}`;
      break;
    case route === '/site-select':
      title = 'Content Management / Search';
      break;
    case route.startsWith('/editorial/create'):
      title = `New ${assetType}`;
      break;
    case route.startsWith('/editorial/edit'):
      if (!record) return;
      const assetTitle = record?.title || '';
      title = assetTitle;
      break;
    case route.startsWith('/editorial/search'):
      title = 'Content Management / Search';
      break;
    case route === '/user/dashboard':
      title = 'Contacts / Dashboard';
      break;
    case route.startsWith('/user/edit'):
      if (!record) return;
      const isNormal = record?.account_type === 'normal';
      const firstName = record?.first_name;
      const lastName = record?.last_name;
      const email = record?.email;
      const identifier = firstName && lastName ? `${firstName} ${lastName}` : email;
      title = isNormal ? `User: ${identifier}` : `Admin: ${identifier}`;
      break;
    case route.startsWith('/user/create'):
      title = `Create User`;
      break;
    case route.startsWith('/user/account/search'):
      title = 'Contacts / Search';
      break;
    case route.startsWith('/search'):
      title = 'Saved Searches';
      break;
    case route.startsWith('/user/admin/search'):
      title = 'Admin Accounts / Search';
      break;
    default:
      break;
  }

  if (title) {
    document.title = `${title} - ${stinger}`;
  } else {
    document.title = stinger;
  }
};

/**
 * Clean string from html tags and blox-inline tags
 * @param {string} input - The input string to clean.
 * @returns {string} - The cleaned string.
 */
export const cleanStringFromHtmlAndBloxInline = (input: string): string => {
  const regex = /<blox-inline([^>]*)>.*?<\/blox-inline>/g;
  const removedBloxInline = input.replace(regex, '');
  return stripHtml(removedBloxInline).result;
};

/**
 * Truncate a string to a specified length and add an ellipsis if the string is longer than the specified length.
 *
 * @param input
 * @param length
 * @returns
 */
export const truncateString = (input: string, length: number): string => {
  return input.length > length ? `${input.slice(0, length).trim()}\u2026` : input.trim();
};

// Find links in an HTML string
export function findLinks(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const anchorTags = doc.getElementsByTagName('a');
  const links = [];

  for (let i = 0; i < anchorTags.length; i++) {
    const href = anchorTags[i].getAttribute('href');
    if (href) {
      links.push(href);
    }
  }

  return links;
}

/**
 * Format currency string to symbol.
 * @param {number} amountInCents - The amount to format in minor units (like cents).
 * @param {string} currencyCode - Will default to USD if falsy... The currency code to use for formatting. (USD, GBP, EUR, etc.)
 * @param {string} [locale] - Set the locale to use for formatting. Falls back to the system's locale if not provided.
 * @returns {string} The formatted currency string. e.g., $123,456.79 for USD.
 */
export const formatCurrency = (
  amountInCents: number,
  currencyCode?: string | undefined,
  locale?: string | undefined,
): string => {
  // Make sure amountInCents is not out of range
  if (
    amountInCents < Number.MIN_SAFE_INTEGER ||
    amountInCents > Number.MAX_SAFE_INTEGER
  ) {
    throw new RangeError('Amount is out of range that can be safely formatted.');
  }
  const amountInDollars = amountInCents / 100;
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode || 'USD', // Default to USD if no currency code is provided. Bewarned, this may be incorrect for some use cases...
    notation: 'standard',
  });
  return formatter.format(amountInDollars);
};

/** Formats a string to display string helper function
 *  mainly used in select dropdowns. Will capitalize the first letter
 *  of the string and return if no match is found.
 * e.g.  * 'html' -> 'HTML'
 */
export const formatAssetString = (assetType: string): string => {
  if (!assetType) {
    return assetType;
  }
  const type = assetType.toLowerCase();
  switch (type) {
    case '*': {
      return 'All';
    }
    case 'html': {
      return 'HTML';
    }
    case 'youtube': {
      return 'YouTube';
    }
    case 'pdf': {
      return 'PDF';
    }
    default: {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
  }
};

/** Convert Tiptap text string to array */
export const convertEditorTextToArray = (editorText: string) => {
  if (!editorText) return [];
  // Split the editorText by newline character and filter out empty strings
  const itemsArray = editorText.split('\n').filter((keyword) => keyword.trim() !== '');
  return itemsArray; // Return array of items
};

/** Convert Tiptap text string to paragraphs */
export const convertEditorTextToParagraphs = (
  editorText: string,
  keepEmptyStrings = false,
) => {
  if (!editorText) return '';

  // Split text by newline character
  let paragraphs = editorText.split('\n');

  // Optionally filter out empty strings
  if (!keepEmptyStrings) {
    paragraphs = paragraphs.filter((keyword) => keyword.trim() !== '');
  }

  // Wrap each line in <p> tags and join them together
  const paragraphString = paragraphs
    .map((keyword) => `<p>${keyword.trim()}</p>`)
    .join('');

  return paragraphString; // Return string of paragraphs
};

/** Convert Tiptap paragraph content to newline */
export const convertParagraphsToNewline = (editorText: string) => {
  if (!editorText) return '';
  // Remove <p> tags and replace them with newline
  const newString = editorText
    .replace(/<\/p>/g, '\n') // Replace closing </p> tags with newline
    .replace(/<p>/g, '') // Remove opening <p> tags
    .trim(); // Trim any leading or trailing whitespace

  return newString;
};

/** Convert paragraph string to array */
export const convertParagraphsToArray = (paragraphString: string) => {
  if (!paragraphString) return [];

  // Split by the <p> tag and filter out empty strings
  const paragraphsArray = paragraphString
    .split('<p>')
    .filter((paragraph) => paragraph.trim() !== '')
    .map((paragraph) => paragraph.replace('</p>', '')); // Remove closing </p> tag

  return paragraphsArray; // Return array of paragraphs
};

/** AssetType + Wildcard as workaround for MUI Select "All" */
export type SearchTypes = AssetType | '*';

//** String Array of SearchTypes */
export const searchTypes: SearchTypes[] = [
  '*', // All if you pass to backend search query type[]: '*'
  'article',
  'image',
  'collection',
  'audio',
  'file',
  'flash',
  'html',
  'link',
  'pdf',
  'poll',
  'table',
  'video',
  'youtube',
  'zip',
];

export interface SearchDropdownOptionsType {
  value: SearchTypes;
  label: string;
}

export const searchDropdownOptions: SearchDropdownOptionsType[] = [
  ...searchTypes.map((type) => ({
    value: type,
    label: formatAssetString(type),
  })),
];

export const searchInitialQueryState: EditorialSearchQueryParams = {
  query: '',
  type: ['*'], // All asset types, asterisk is workaround for MUI Select.
  deleted: 'false', // Only show non-deleted records.
};

export const relatedAssetOptions: string[] = ['children', 'siblings', 'parents'];

/** setValue react-hook-form commonly used config */
export const shouldValidDirtyTouch: SetValueConfig = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

/** Transform BloxSearchResultDataItem to RelatedAsset for Collections */
export const searchItemToRelatedAsset = (
  item: EditorialAssetSearchResultItem,
): RelatedAsset => {
  if (!item.uuid) {
    throw new Error(
      '[searchItemToRelatedAsset]: Item is missing a UUID: ' + String(item),
    );
  }
  return {
    // per slack discussion w/ Brian, all assets from the /editorial/ search endpoint will be 'editorial'
    // but 'app' isn't returned in /editorial/asset/search/ - here defaults to 'editorial' it here in case the app isnt defined
    app: item?.app || 'editorial',
    uuid: item?.uuid,
    title: item?.title,
    type: item?.type,
    start_time: item?.start_time,
    authors: item?.authors,
    previews: {
      display: {
        url: item?.previews?.['size1']?.url,
      },
    },
  };
};

/** Format a single UserAccountSummary object
 *  tries to concatenate the first name and last name, else uses the screen name
 *  and falls back to email address, and as a very last ditch resort, the uuid...
 */
export const formatAuthor = (author: UserAccountSummary): string => {
  const formatted =
    [author.first_name, author.last_name].filter(Boolean).join(' ') ||
    author.screen_name ||
    author.email ||
    author.uuid;
  return String(formatted).trim();
};

/** Provided an array of `UserAccountSummary` objects, uses maps using formatAuthor formula.
 * e.g. expected output: "Mike Smith, some_username, Rob" */
export const getAuthorsStringFormatted = (authors: UserAccountSummary[]): string => {
  if (authors.length === 0) return '';
  return authors.map(formatAuthor).join(', ');
};

/**
 * Provided an array of `UserAccountSummary` objects, using the formatAuthor formula,
 * tries to concatenate the first name and last name, else uses the screen name.
 * If there are more than one author, it appends " ...(x more)" where x is the count of remaining authors.
 *
 * e.g. expected output: "Mike Smith... (x more)"
 */
export const getAuthorsStringTruncated = (authors: UserAccountSummary[]): string => {
  if (authors.length === 0) return '';
  const formattedName = formatAuthor(authors[0]);
  if (authors.length > 1) {
    return `${formattedName}... (${authors.length - 1} more)`;
  }
  return formattedName;
};

/**
 * Pass in a React.DragEvent and try to parse the dataTransfer as JSON.
 * Returns the parsed JSON or false if it fails.
 */
export const tryParseDropEventAsJson = (event: React.DragEvent): any => {
  try {
    return JSON.parse(event?.dataTransfer?.getData('application/json'));
  } catch (error) {
    console.error(
      '[tryParseDropEventAsJson] - Failed to parse dropped data as JSON:',
      error,
    );
    return false;
  }
};

/**
 * Converts a number of days into a pretty-readable format with dayJS and regex.
 * @param days - The number of days to convert.
 * @param withoutSuffix - Pass the suffix to the dayjs.to() function. (e.g. true = month, false = in a month)
 * @returns A pretty-readable string representing the number of days.
 * @example daysToPrettyFormat(30) => 'month'
 * @todo This may not work for all cases, needs more testing, added for demo deadline.
 */
export const daysToPrettyFormat = (days: number, withoutSuffix: boolean = true) => {
  const date = dayjs().add(days, 'day');
  return dayjs().to(date, withoutSuffix); // 'true' for without suffix
};

/**
 * Converts a date string into a pretty-readable format with dayJS.
 * @param dateString - The date string to convert.
 * @returns A pretty-readable string representing the relative time.
 * @example dateToPrettyFormat('2023-07-09T14:30:00Z') => '5 minutes ago', 'Today at 1:00AM', 'one year ago'
 */
export const dateToPrettyFormat = (dateString: string) => {
  const now = dayjs();
  const date = dayjs(dateString);

  if (date.isSame(now, 'day')) {
    const diffInMinutes = now.diff(date, 'minute');
    if (diffInMinutes < 1) {
      return 'just now';
    } else if (diffInMinutes < 60) {
      return diffInMinutes === 1 
        ? `${diffInMinutes} minute ago` 
        : `${diffInMinutes} minutes ago`;
    } else {
      return `today at ${date.format('h:mm A')}`;
    }
  } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    return `yesterday at ${date.format('h:mm A')}`;
  } else {
    return date.fromNow();
  }
};

/** DateField Intl Date Format Options for User/Subscription Table `Intl.DateTimeFormatOptions` */
export const userDateFormat: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

/**
 * Converts a card type string to a formatted card name.
 *
 * @param {string} cardType - The type of the card (e.g., 'visa', 'master', 'american_express', 'discover').
 * @returns {string} The formatted card name.
 *
 * @example
 * getCardName('visa'); // returns 'Visa'
 * getCardName('master'); // returns 'Mastercard'
 * getCardName('american_express'); // returns 'American Express'
 * getCardName('discover'); // returns 'Discover'
 * getCardName('other_card'); // returns 'Other Card'
 */
export const getCardName = (cardType: string | null): string => {
  if (cardType === null || cardType === undefined) {
    return 'Unknown';
  }

  switch (cardType) {
    case 'visa':
      return 'Visa';
    case 'master':
      return 'Mastercard';
    case 'american_express':
      return 'American Express';
    case 'discover':
      return 'Discover';
    default:
      return cardType.charAt(0).toUpperCase() + cardType.slice(1);
  }
};

/**
 * Formats a phone number string to a standard format.
 *
 * @param {string} value - The phone number string to format.
 * @returns {string} The formatted phone number string.
 *
 * @example
 * formatPhoneNumber('1234567890'); // returns '(123) 456-7890'
 */
export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
    6,
    10,
  )}`;
};

/**
 * Checks if a card is expired based on the expiration month and year.
 *
 * @param {number} expirationMonth - The expiration month of the card.
 * @param {number} expirationYear - The expiration year of the card.
 * @returns {boolean} True if the card is expired; false otherwise.
 *
 * @example
 * isCardExpired(10, 2022); // returns true if the card is expired
 */
export const isCardExpired = (
  expirationMonth: number,
  expirationYear: number,
): boolean => {
  const currentDate = new Date();
  const expirationDate = new Date(expirationYear, expirationMonth - 1); // Months are zero-indexed
  return expirationDate < currentDate;
};

/** Accepted File Upload Input MIME Types Array for File Asset + Inline File */
export const acceptedFileTypes: string[] = [
  // --- Archive file types ---
  'application/zip', // IANA RFC official .zip
  'application/x-zip-compressed', // windows .zip type
  'application/zip-compressed', // non-standard .zip used by some programs
  // NOTE: Some unaccepted zip types (bz, bz2, 7z, rar) will misclassify themselves as an accepted type
  // In this case the client cant reject instantly, but the backend will respond with an invalid type upon upload completion.
  // --- Individual file types ---
  'application/pdf', // .pdf
  'application/msword', // .doc, .dot, .wiz
  'application/dot', // .dot word template
  'application/vnd.ms-powerpoint', // .ppt, .pot, .pps, .ppa
  'application/vnd.ms-excel', // .xla, .xlb, .xlc, .xlm, .xls, .xlt, .xlw
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
];

/** Uses remeda's omitBy to remove null or undefined values from an object
 *
 * `note: will not remove empty arrays, or empty objects`
 *
 * `use removeAllEmptyNullUndefinedValues to also remove empty arrays and objects`
 *
 * @param obj - The object to remove null or undefined values from.
 * @returns The object with keys that have values of null or undefined removed.
 *
 * @description omitBy - Creates a shallow copy of the data, and then removes any keys that the predicate rejects.
 * Symbol keys are not passed to the predicate and would be passed through to the output as-is.
 **/
export function removeNullOrUndefinedValues<T extends AnyRecord>(obj: T): Partial<T> {
  return omitBy(obj, (value) => value === null || value === undefined) as Partial<T>;
}

/**
 * Converts a string to a color based on the string's hash code.
 * 
 * @param string 
 * @returns string
 */
export function stringToColor(string: string) {
  let hash = 0;
  let i;

  if(string.length === 0) return 'inherit'

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

/**
 * Returns text color based on the background color.
 * 
 * @param backgroundColor 
 * @param theme 
 * @returns string
 */
export function getTextColor(backgroundColor: string, theme: Theme) {
  // Convert hex color to RGB components
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Calculate perceived brightness using luminance formula
  const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Choose white or black based on brightness threshold
  return brightness > 0.5 ? theme.palette.common.black : theme.palette.common.white; // Black for light backgrounds, White for dark backgrounds
}

/**
 * Removes any keys of an object that have values such as
 * null, undefined, empty strings, empty arrays, or empty objects.
 * @example
 * const obj = { key1: null, key2: undefined, key3: '', key4: [], key5: {}, key6: 'hello' };
 * const cleanedObj = removeNullUndefinedEmptyValues(obj);
 * console.log(cleanedObj); // { key6: 'hello' }
 */
export function removeAllEmptyNullUndefinedValues<T extends AnyRecord>(
  obj: T,
): Partial<T> {
  return omitBy(obj, (value) => {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === 'string' && value.trim() === '') {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0
    ) {
      return true;
    }
    return false;
  }) as Partial<T>;
}

/** Returns a boolean indicating if string or array has a length greater than 0.
 *  @description null or undefined values return false.
 **/
export function hasLength(value: string | any[] | null | undefined): boolean {
  if (value === null || value === undefined) {
    return false;
  }
  return Boolean(value?.length > 0);
}
