import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react';
import { useQuery } from 'react-query';
import { useCosmosRAProviders } from './CosmosRAProvidersContext';
import { UserAccountSummary, UserAccountGroups } from '../../utility/types/dataProvider';
import { useCosmos } from './CosmosContext';
import ErrorAlerts, { ErrorDetails } from '../../components/generic/ErrorAlerts';
import SkeletonBox from '../../components/generic/SkeletonBox';

type CosmosActiveUserContextType = {
  userSummary: UserAccountSummary | null;
  setUserSummary: Dispatch<SetStateAction<UserAccountSummary | null>>;
  userGroups: UserAccountGroups | null;
  setUserGroups: Dispatch<SetStateAction<UserAccountGroups | null>>;
  userPermissions: AppPermissions | undefined;
  setUserPermissions: Dispatch<SetStateAction<AppPermissions | undefined>>;
  isLoading: boolean;
  isError: boolean;
};

type EditorialPermissions = {
  full_access: boolean;
  batch_edit_search: boolean;
  change_publish_status: boolean;
  create_assets: boolean;
  delete_assets: boolean;
  modify_assets: boolean;
  change_process: boolean;
  change_workflow: boolean;
};

type UserPermissions = {
  full_access: boolean;
  manage_groups: boolean;
  manage_profiles: boolean;
};

// Will grow as more permissions are added
type AppPermissions = {
  editorial_asset: EditorialPermissions;
  user: UserPermissions;
};

// Create user context
export const CosmosActiveUserContext = createContext<CosmosActiveUserContextType>({
  userSummary: null,
  setUserSummary: () => {},
  userGroups: null,
  setUserGroups: () => {},
  userPermissions: undefined,
  setUserPermissions: () => {},
  isLoading: false,
  isError: false,
});

// CosmosUserProvider component
export const CosmosActiveUserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { dataProvider } = useCosmosRAProviders();
  const { sessionConfig } = useCosmos();
  const [userSummary, setUserSummary] = useState<UserAccountSummary | null>(null);
  const [userGroups, setUserGroups] = useState<UserAccountGroups | null>(null);
  const [userPermissions, setUserPermissions] = useState<AppPermissions | undefined>();

  const fetchUserSummary = async () => {
    return await dataProvider.getActiveUserSummary();
  };

  const fetchUserGroups = async () => {
    return await dataProvider.getActiveUserGroups();
  };

  const {
    isLoading: userSummaryLoading,
    isError: userSummaryError,
    error: userSummaryErrorData,
  } = useQuery<UserAccountSummary, Error>('getActiveUserSummary', fetchUserSummary, {
    staleTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    onSuccess: (data) => {
      setUserSummary(data);
    },
  });

  const {
    isLoading: userGroupsLoading,
    isError: userGroupsError,
    error: userGroupsErrorData,
  } = useQuery<UserAccountGroups, Error>('getActiveUserGroups', fetchUserGroups, {
    staleTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    onSuccess: (data) => {
      setUserGroups(data);
    },
  });

  const getPermissions = useCallback((allPermissions: string[]): AppPermissions => {
    const configPermissions: AppPermissions = {
      editorial_asset: {
        full_access: false,
        batch_edit_search: false,
        change_publish_status: false,
        create_assets: false,
        delete_assets: false,
        modify_assets: false,
        change_process: false,
        change_workflow: false,
      },
      user: {
        full_access: false,
        manage_groups: false,
        manage_profiles: false,
      },
    };

    // Check if "full-access" is in the allPermissions array
    if (allPermissions.includes('full-access')) {
      // Iterate over each permission type and set all permissions to true
      Object.keys(configPermissions).forEach((permissionType) => {
        // Get the permissions object for the current permission type
        const permissionsObject =
          configPermissions[permissionType as keyof AppPermissions];
        // Set all permissions for the current type to true
        Object.keys(permissionsObject).forEach((permissionKey) => {
          permissionsObject[permissionKey as keyof typeof permissionsObject] = true;
        });
      });
      return configPermissions;
    }

    // If not "full-access" iterate over each permission and set true if found in the allPermissions array
    allPermissions.forEach((permission) => {
      const [application, , ...details] = permission.split('/');
      const permissionApp = application.replace(/-/g, '_');

      // If the application is in our config set permissions
      if (permissionApp === 'editorial_asset' || permissionApp === 'user') {
        // Check for full access
        let fullAccess = false;
        if (details.includes('Full access')) {
          fullAccess = true;
        } else {
          // Create permissions array for check
          const simplifiedPermission = details.map((detail) => {
            return detail.toLowerCase().replace(/\s+/g, '_');
          });
          // Set the permission to true if it's found in the details
          simplifiedPermission.forEach((permission) => {
            if (permission in configPermissions[permissionApp]) {
              configPermissions[permissionApp][
                permission as keyof (typeof configPermissions)[typeof permissionApp]
              ] = true;
            }
          });
        }

        // If full access is found, set all permissions of the permission type to true
        if (fullAccess) {
          Object.keys(configPermissions[permissionApp]).forEach((key) => {
            if (key !== 'fullAccess') {
              configPermissions[permissionApp][
                key as keyof (typeof configPermissions)[typeof permissionApp]
              ] = true;
            }
          });
          configPermissions[permissionApp].full_access = true;
        }
      }
    });

    return configPermissions;
  }, []);

  useMemo(() => {
    setUserPermissions(getPermissions(sessionConfig.permissions ?? []));
  }, [sessionConfig.permissions, getPermissions]);

  const isLoading = userSummaryLoading || userGroupsLoading;
  const isError = userSummaryError || userGroupsError;

  const contextValue = useMemo(
    () => ({
      userSummary,
      setUserSummary,
      userGroups,
      setUserGroups,
      userPermissions,
      setUserPermissions,
      isLoading,
      isError,
    }),
    [userSummary, userGroups, userPermissions, isLoading, isError],
  );

  if (isLoading) {
    return (
      <SkeletonBox
        text="Configuring active user..."
        width={'100%'}
        height={'100%'}
      />
    );
  }

  if (isError) {
    const errors: ErrorDetails[] = [];

    if (userSummaryError && userSummaryErrorData) {
      errors.push({
        message: userSummaryErrorData.message || 'Error fetching user summary data.',
        status: 'Error',
        error: userSummaryErrorData,
      });
    }

    if (userGroupsError && userGroupsErrorData) {
      errors.push({
        message: userGroupsErrorData.message || 'Error fetching user groups data.',
        status: 'Error',
        error: userGroupsErrorData,
      });
    }

    console.log('CosmosActiveUserContext Errors:', JSON.stringify(errors, null, 2));
    return <ErrorAlerts errors={errors} />;
  }

  return (
    <CosmosActiveUserContext.Provider value={contextValue}>
      {children}
    </CosmosActiveUserContext.Provider>
  );
};

export const useCosmosUser = () => {
  const context = useContext(CosmosActiveUserContext);
  if (!context) {
    throw new Error('useCosmosUser must be used within CosmosUserContextProvider!');
  }
  return context;
};
