import { Interpolation, PaletteOptions, Theme, ThemeOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { BreakpointsOptions, Direction, ShapeOptions, SpacingOptions } from '@mui/system';
import { CSSProperties } from 'react';
import { RaThemeOptions } from 'react-admin';

// --- CONSTANTS REFERENCED IN THEME FILES ---
type DefinedCSSPropertiesColorBloxColors = Exclude<CSSProperties['color'], undefined>;
type BloxColorKeys =
  | 'white'
  | 'offwhite'
  | 'midlightgray'
  | 'gray'
  | 'middarkgray'
  | 'darkgray'
  | 'medgray'
  | 'gray'
  | 'lightgray'
  | 'bluegray'
  | 'black'
  | 'offblack'
  | 'red'
  | 'darkModeRed'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'darkModeBlue'
  | 'iceblue'
  | 'logoblue'
  | 'indigo'
  | 'violet'
  | 'gray1'
  | 'gray2'
  | 'gray3'
  | 'gray4'
  | 'gray5'
  | 'gray6'
  | 'link';

export type BloxColorsType = {
  [key in BloxColorKeys]: DefinedCSSPropertiesColorBloxColors;
};

type BloxIconKeys =
  | 'alink'
  | 'article'
  | 'audio'
  | 'batchUpload'
  | 'collection'
  | 'file'
  | 'form'
  | 'html'
  | 'iframe'
  | 'image'
  | 'import'
  | 'metadata'
  | 'oovvuu'
  | 'pdf'
  | 'poll'
  | 'table'
  | 'video'
  | 'youtube'
  | 'zip'
  | 'contentmgmt';

export type BloxIconColorsType = {
  [key in BloxIconKeys]: DefinedCSSPropertiesColorBloxColors;
};

export type BloxValuesType = {
  darkenCoefficient: number;
  lightenCoefficient: number;
  appBarHeight: number;
  /** defaultBorderRadius / 2 */
  halfBorderRadius: number;
  /** defaultBorderRadius / 4 */
  quarterBorderRadius: number;
  /** 12px @ standard 16px base browser fontsize */
  tinyFontSizeRem: string;
  /** 14px @ standard 16px base browser fontsize */
  smallFontSizeRem: string;
  /** 16px @ standard 16px base browser fontsize */
  regularFontSizeRem: string;
  /** 18px @ standard 16px base browser fontsize */
  xlFontSizeRem: string;
  /** 20px @ standard 16px base browser fontsize */
  xxlFontSizeRem: string;
  /** zIndex: 9999 */
  zIndexMax: number;
  /** 300 */
  fontWeightLight: number;
  /** 400 */
  fontWeightRegular: number;
  /** 500 */
  fontWeightMedium: number;
  /** 700 */
  fontWeightBold: number;
};

type ExtendedComponents = ThemeOptions['components'] & RaThemeOptions['components'];
export interface CustomThemeOptions
  extends Omit<ThemeOptions, 'components'>,
    Omit<RaThemeOptions, 'components'> {
  components?: ExtendedComponents;
  blox: {
    colors: BloxColorsType;
    values: BloxValuesType;
    iconColors: BloxIconColorsType;
    table: BloxTableColorsType;
    chart: BloxChartColors;
    marketing: BloxMarketingColorsType;
  };
}

export const defaultBorderRadius = 4;

export const shape: ShapeOptions = {
  borderRadius: defaultBorderRadius,
};

export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightBold = 700;

// Common values we can reference in one place via theme.blox.values.*
export const bloxValues: BloxValuesType = {
  appBarHeight: 64,
  darkenCoefficient: 0.25,
  lightenCoefficient: 0.25,
  zIndexMax: 9999,
  halfBorderRadius: defaultBorderRadius / 2,
  quarterBorderRadius: defaultBorderRadius / 4,
  tinyFontSizeRem: '0.75rem',
  smallFontSizeRem: '0.875rem',
  regularFontSizeRem: '1rem',
  xlFontSizeRem: '1.125rem',
  xxlFontSizeRem: '1.25rem',
  fontWeightLight: fontWeightLight,
  fontWeightRegular: fontWeightRegular,
  fontWeightMedium: fontWeightMedium,
  fontWeightBold: fontWeightBold,
};

// Blox Colors - these direct values apply if accessing via theme.blox.colors.*
// Gray shades spaced evenly between offwhite and offblack
// https://coolors.co/gradient-palette/fdfdfd-0f0f0f?number=7
export const bloxColors: BloxColorsType = {
  white: '#FFFFFF',
  offwhite: '#FDFDFD',
  lightgray: '#D5D5D5',
  midlightgray: '#AEAEAE',
  gray: '#868686',
  middarkgray: '#5E5E5E',
  medgray: '#4D4D4D',
  darkgray: '#373737',
  offblack: '#0F0F0F',
  black: '#000000',
  bluegray: '#344563',
  red: '#CB3300',
  darkModeRed: '#DA420F', // slightly lighter error red for dark mode
  orange: '#D5581A',
  yellow: '#E2B93B',
  green: '#38B349',
  blue: '#1879BD',
  darkModeBlue: '#2A8BCF', // slightly lighter primary blue for dark mode
  iceblue: '#D2E5F2',
  logoblue: '#00A3E1',
  indigo: '#4938B3',
  violet: '#B338A2',
  gray1: '#4F5B79',
  gray2: '#7F8FA4',
  gray3: '#979797',
  gray4: '#F8F8F8',
  gray5: '#f2f5f9', // Soft blue-gray.
  gray6: '#eaf0f7', // Soft blue-gray hover.
  link: '#1778BC',
};

// Place any global styles here that can't be added to the theme overrides.
// e.g. to customize the browser's scrollbar, selection, html elements, etc.
// can think of this as the classic index.css file for index.html
export const globalStyles: Interpolation<Theme> = {
  '::selection': {
    background: bloxColors.logoblue,
  },
  '::-moz-selection': {
    background: bloxColors.logoblue,
  },
  // Global scrollbar overrides
  '*::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '*::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '*::-webkit-scrollbar-track:hover': {
    background: 'light-dark(#0000000D,#FFFFFF1A)',
  },
  '*::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
  '*::-webkit-scrollbar-button': {
    display: 'none',
  },
  '*::-webkit-scrollbar-thumb': {
    background: 'light-dark(#0000001A,#FFFFFF33)',
  },
  '*::-webkit-scrollbar-thumb:hover': {
    background: 'light-dark(#00000033,#FFFFFF66)',
  },

  'html, body': {
    display: 'contents', // Go straight to MainLayout grid as first css box rendered, may also help accessibility tree.
    height: '100%',
    width: '100%',
    minHeight: '100%',
    minWidth: '100%',
    margin: '0',
    padding: '0',
    color: 'inherit',
    // lineHeight: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    fontFeatureSettings: 'normal',
    textWrap: 'pretty',
    textRendering: 'optimizeLegibility',
  },

  // See note in MainLayout.tsx about these root targets..
  '#root': {
    display: 'inherit', // Inherit from html/body (contents) - if needed, can also inherit max-h/w and w/h here.
  },
  // Tries to target the ScopedCssBaseline inside RA's <AdminUI>
  '#root > .MuiScopedCssBaseline-root': {
    display: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit', // Overriding the scoped-css-basline setting this to a strange value...
  },
  // More notes on this in MainLayout.tsx - tries to target the plain RA div at:
  // AdminUI > CoreAdminUI > CoreAdminRoutes >  return element={ <div> ... <Layout>... }
  '#root > .MuiScopedCssBaseline-root > div:first-of-type': {
    display: 'inherit',
  },
};

// Blox Icon Colors - these direct values apply if accessing via theme.blox.iconColors.*
export const bloxIconColors: BloxIconColorsType = {
  alink: '#006dc3',
  article: '#295497',
  audio: '#555ba4',
  batchUpload: '#000000',
  collection: '#d14424',
  file: '#37aec1',
  form: '#000000',
  html: '#000000',
  iframe: '#CC2D79',
  image: '#1ea261',
  import: '#000000',
  metadata: '#000000',
  oovvuu: '#00ac99',
  pdf: '#a33539',
  poll: '#68ac73',
  table: '#1f7244',
  video: '#f70000',
  youtube: '#B2071D',
  zip: '#000000',
  // Application branding colors
  contentmgmt: '#3137BF',
};

export const bloxMarketingColors = {
  primary: '#02528a',
  secondary: '#a7d400',
  tertiary: 'rgba(95,36,159,1)',
  warning: bloxColors.blue,
  error: '#e4002b',
};
export type BloxMarketingColorsType = typeof bloxMarketingColors;

export const bloxTableColors = {
  border: '#313E4D',
  hover: '#293541',
  link: bloxIconColors.alink,
  text: bloxColors.bluegray,
  trendingDown: bloxIconColors.pdf,
  trendingFire: '#f57c00',
  trendingUp: bloxIconColors.image,
  legendFire: '#fac895',

  // Trending colors
  neutral: bloxColors.white,
  primary: bloxColors.blue,
  secondary: bloxIconColors.image,
  error: bloxIconColors.youtube,
  warning: '#f57c00',
  info: bloxIconColors.audio,
};
export type BloxTableColorsType = typeof bloxTableColors;

// Listed in priority for pie chart.
export const bloxChartColors = {
  blue: bloxIconColors.article,
  liteBlue: bloxIconColors.file,
  gray: '#3F4D5E',
  green: bloxIconColors.table,
  liteGreen: bloxIconColors.image,
  purple: bloxIconColors.audio,
  red: bloxIconColors.pdf,
  magenta: bloxIconColors.iframe,
  orange: bloxIconColors.collection,
};
export type BloxChartColors = typeof bloxChartColors;

// --- BASE THEME CONFIG ---
export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
  unit: 'px',
  step: 5,
};

export const direction: Direction = 'ltr';

export const spacing: SpacingOptions = 8;

export const lineHeightNormalize = 1.2;

export const typography: TypographyOptions = {
  htmlFontSize: 16, // base font size for the html document
  fontSize: 16, // default font size for components
  fontFamily:
    'Roboto,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontWeightLight: fontWeightLight,
  fontWeightRegular: fontWeightRegular,
  fontWeightMedium: fontWeightMedium,
  fontWeightBold: fontWeightBold,
  allVariants: {
    lineHeight: lineHeightNormalize,
    letterSpacing: 'normal',
    textWrap: 'pretty',
    textRendering: 'optimizeLegibility',
    fontFeatureSettings: 'normal',
  },
  h1: {
    fontSize: bloxValues.xxlFontSizeRem,
    fontWeight: fontWeightMedium,
  },
  h2: {
    fontSize: bloxValues.regularFontSizeRem,
    fontWeight: fontWeightMedium,
  },
  subtitle1: {
    fontSize: bloxValues.smallFontSizeRem,
    fontWeight: fontWeightRegular,
    color: bloxColors.gray,
  },
};

// --- SPECIFIC LIGHT/DARK BASE THEME CONFIG / OVERRIDES ---
export const lightPalette: PaletteOptions = {
  mode: 'light',
  contrastThreshold: 4,
  tonalOffset: 0.2,
  common: {
    black: bloxColors.black,
    white: bloxColors.white,
  },
  background: {
    default: bloxColors.white,
    paper: bloxColors.offwhite,
  },
  text: {
    primary: bloxColors.black,
    secondary: bloxColors.middarkgray,
    disabled: bloxColors.gray,
  },
  divider: bloxColors.lightgray,
  primary: {
    main: bloxColors.blue,
    contrastText: bloxColors.white,
  },
  secondary: {
    main: bloxColors.green,
  },
  error: {
    main: bloxColors.red,
    contrastText: bloxColors.white,
  },
  warning: {
    main: bloxColors.orange,
  },
  info: {
    main: bloxColors.gray,
  },
  success: {
    main: bloxColors.green,
    contrastText: bloxColors.white,
  },
};

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  contrastThreshold: 4,
  tonalOffset: 0.2,
  common: {
    black: bloxColors.black,
    white: bloxColors.white,
  },
  background: {
    default: bloxColors.black,
    paper: bloxColors.offblack,
  },
  text: {
    primary: bloxColors.white,
    secondary: bloxColors.gray,
    disabled: bloxColors.middarkgray,
  },
  divider: bloxColors.darkgray,
  primary: {
    main: bloxColors.darkModeBlue,
    contrastText: bloxColors.white,
  },
  secondary: {
    main: bloxColors.green,
  },
  error: {
    main: bloxColors.darkModeRed,
    contrastText: bloxColors.white,
  },
  warning: {
    main: bloxColors.orange,
  },
  info: {
    main: bloxColors.gray,
  },
  success: {
    main: bloxColors.green,
    contrastText: bloxColors.white,
  },
};
