import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorAlerts, { ErrorDetails } from '../../components/generic/ErrorAlerts';
import NoCosmosPermissions from '../../components/generic/NoCosmosPermissions';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import { capitalizeFirstLetter, getRoute } from '../../utility/functions';
import { SessionConfigResult } from '../../utility/types/dataProvider';
import { useCosmosAuthEnv } from './CosmosAuthEnvContext';
import { v4 as uuidv4 } from 'uuid';

export interface CosmosContextType {
  changeActiveSiteAsync: (newDefaultSite: string) => Promise<void>;
  clearInvalidDomain: () => void;
  /** The currently 'active' site/domain. */
  defaultSite: string;
  invalidDomain: string;
  application?: string;
  route?: string;
  sessionConfig: SessionConfigResult;
  oidcDefaultSite: string;
}

export interface StateValuesType {
  defaultSite: string;
  invalidDomain: string;
  sessionConfig: SessionConfigResult;
}

const initialStateValues: StateValuesType = {
  defaultSite: '',
  invalidDomain: '',
  sessionConfig: {
    domain: '',
    site_name: '',
    bookmark_icon: {
      url: '',
      width: 0,
      height: 0,
    },
    time_zone: '',
    permissions: [],
    experiments: [],
    software_privileges: {
      core: [],
      wire: [],
      editorial: [],
      pay: [],
      broadcast: [],
      weather: [],
    },
  },
}; // Default state value for the initialStateValues / typescript inference.

export const CosmosContext = createContext<CosmosContextType | undefined>(undefined);

export const useCosmos = () => {
  const context = useContext(CosmosContext);
  if (!context) {
    throw new Error('useCosmos must be used within a CosmosProvider!');
  }
  return context;
};

// We can't access the dataProvider initially, so this one API call will need to be defined here.
// The similar method in the the dataProvider will still be accessible once dataProvider is built.
function initialSessionConfig(
  apiUrl: string,
  authToken: string,
  xBloxDomain: string,
): Promise<SessionConfigResult> {
  return new Promise<SessionConfigResult>(async (resolve, reject) => {
    if (!apiUrl || !authToken || !xBloxDomain) {
      reject(new Error('initialSessionConfig() - Missing required parameters: apiUrl, authToken, or xBloxDomain'));
      return;
    }
    try {
      const headers = new Headers({
        Accept: 'application/json',
        Authorization: authToken,
        'X-Blox-Domain': xBloxDomain,
        'X-Request-Id': uuidv4(), // Add a unique request ID for tracking
      });
      const response = await fetch(`${apiUrl}/core/session/config/`, { headers });
      if (!response.ok) {
        const json = await response.json();
        const errorObj = {
          status: `Status Code: ${response?.status}`,
          message: `Message: ${json?.message || '(No message provided)'}`,
          details: json,
        };
        throw errorObj;
      }
      const json = await response.json();
      if (json.status === 'success') {
        const sessionConfig: SessionConfigResult = json.data;
        resolve(sessionConfig);
      } else if (json.status === 'error') {
        const errorObj = {
          status: `Status Code: ${response?.status}`,
          message: `Message: ${json?.message || '(No message provided)'}`,
          details: json,
        };
        throw errorObj;
      } else {
        throw new Error(`Invalid response from API:\n${JSON.stringify(json, null, 2)}`);
      }
    } catch (error) {
      console.error('initialSessionConfig() error: ' + JSON.stringify(error, null, 2));
      reject(error);
    }
  });
}

export const CosmosProvider = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const { envState } = useCosmosAuthEnv();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errorResults, setErrorResults] = useState<ErrorDetails[]>([]); // Errors sent to this array in onError callbacks.
  const [stateValues, setStateValues] = useState<StateValuesType>(initialStateValues);
  const authBloxCMSDefaultSite = String(
    auth?.user?.profile['bloxcms.default_site'],
  ).toLowerCase();
  const apiUrl = `https://${envState?.API_SERVER_URL}/rest/admin`;
  const authToken = `${capitalizeFirstLetter(String(auth?.user?.token_type))} ${
    auth?.user?.access_token
  }`; // "Bearer <token>"

  // Application and route information
  const location = useLocation();
  const currentRoute = getRoute(location.pathname);
  const pathnames = currentRoute.split('/').filter((x) => x);
  const currentApplication = pathnames[0] ?? 'dashboard';

  // initializeCosmosQuery (fetches sessionConfig, and sets the default/active site) - starts once auth is valid.
  const initializeCosmosQuery = useQuery(
    ['initializeCosmosQuery', authBloxCMSDefaultSite, auth.isAuthenticated],
    async () => {
      if (!authBloxCMSDefaultSite) {
        throw new Error('OIDC Auth bloxcms.default_site is undefined/missing.');
      }
      let addressBarDomain = window.location.pathname.split('/')[1]?.toLowerCase();
      try {
        // Edge case: localhost:3000/ (no domain in address bar), we'll throw to oidc fallback catch block.
        if (!addressBarDomain) {
          throw new Error('URL domain is undefined, falling back to oidc domain.');
        }
        // Try using the address bar domain first.
        const sessionConfigResult = await initialSessionConfig(
          apiUrl,
          authToken,
          addressBarDomain,
        );

        // If success, the domain in the addressBar is valid.
        const stateConfig: StateValuesType = {
          sessionConfig: sessionConfigResult,
          defaultSite: addressBarDomain, // 'active' site used for routing
          invalidDomain: '',
        };
        return stateConfig;
      } catch (error) {
        try {
          // If errored it's likely due to the domain from address bar invalid, no permissions to access, etc.
          // Try to initialize the app using the oidc default site. Hopefully the oidc default site is valid.
          const sessionConfigResult = await initialSessionConfig(
            apiUrl,
            authToken,
            authBloxCMSDefaultSite,
          );

          const stateConfig: StateValuesType = {
            sessionConfig: sessionConfigResult,
            defaultSite: authBloxCMSDefaultSite, // 'active' site used for routing - fallback to their oidc default site.
            invalidDomain: addressBarDomain, // whatever was in the address bar as domain, it's invalid.
          };
          return stateConfig;
        } catch (error) {
          // If this errored likely user lacks cosmos permissions, or failed to fetch, etc. Throw to handle in onError.
          throw error;
        }
      }
    },
    {
      enabled: auth.isAuthenticated && !auth.isLoading, // Enables and runs when the auth is valid and not loading.
      staleTime: Infinity, // Never auto re-fetch
      retry: false, // Never retry upon failure
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        // Update the state with the results of the fetches.
        setStateValues((prevValues) => ({ ...prevValues, ...data }));
      },
      onError: (error) => {
        setErrorResults((prevArray) => [
          ...prevArray,
          {
            status: (error as ErrorDetails)?.status,
            message: (error as ErrorDetails)?.message,
            error: error,
          },
        ]);
      },
    },
  );

  // Mutation: Swap to the domain string provided.
  const { mutateAsync: changeActiveSiteAsync } = useMutation(
    async (newActiveSite: string) => {
      // On swapped site - Clear/reset any errors, queries, states as we are about to refetch / reinitialize.
      queryClient.clear();
      await queryClient.resetQueries();
      // Reset the state values.
      setStateValues(initialStateValues);
      setErrorResults([]);
      // Navigate to the new site domain before refetch since the address bar domain is used in the inital query.
      navigate(`/${newActiveSite.toLowerCase()}`);
      // Refetch initializeCosmosQuery.
      await initializeCosmosQuery.refetch({ throwOnError: true });
    },
    {
      onSuccess: () => {},
      onError: (error) => {
        console.error('Failed to swap sites - error:', error);
        setErrorResults((prevArray) => [
          ...prevArray,
          {
            status: (error as ErrorDetails)?.status,
            message: (error as ErrorDetails)?.message,
            error: error,
          },
        ]);
      },
    },
  );

  const contextValue = useMemo(() => {
    return {
      defaultSite: stateValues.defaultSite, // Note: defaultSite is the 'active' domain in the address bar.
      oidcDefaultSite: authBloxCMSDefaultSite, // Note: oidcDefaultSite is the user's oidc default site.
      invalidDomain: stateValues.invalidDomain,
      application: currentApplication,
      route: currentRoute,
      sessionConfig: stateValues.sessionConfig,
      changeActiveSiteAsync: changeActiveSiteAsync,
      clearInvalidDomain: () => {
        setStateValues((prevValues) => ({
          ...prevValues,
          invalidDomain: '',
        }));
      },
    };
  }, [
    authBloxCMSDefaultSite,
    changeActiveSiteAsync,
    currentApplication,
    currentRoute,
    stateValues.defaultSite,
    stateValues.invalidDomain,
    stateValues.sessionConfig,
  ]);

  if (errorResults.length !== 0) {
    for (const error of errorResults) {
      console.error('CosmosProvider Errors:' + JSON.stringify(error, null, 2));
    }
    return <ErrorAlerts errors={errorResults} />;
  }

  if (
    !initializeCosmosQuery.data ||
    initializeCosmosQuery.isLoading ||
    initializeCosmosQuery.isFetching
  ) {
    // Note this splashscreen can also show while awaiting the logout response from the oidc server
    // as the auth can invalidate the the 'enabled' flag on the initializeCosmosQuery causing react tree to re-render.
    return <BloxLoadingSplash message="Please wait..." />;
  }

  // If the user lacks cosmos permissions, show the NoCosmosPermissions component.
  if (!stateValues?.sessionConfig?.software_privileges?.core?.includes('cosmos')) {
    return <NoCosmosPermissions />;
  }

  return <CosmosContext.Provider value={contextValue}>{children}</CosmosContext.Provider>;
};
